const initialState = {
  loading: false,
  error: null,
  productAdded: false,
  productData: null,
};
const arrayBuffer = {
  loading: false,
  error: null,
  data: null,
};
const allProduct = {
  loading: false,
  error: null,
  allProducts: null,
};

const product = {
  loading: false,
  error: null,
  product: null,
};
const products = {
  loading: false,
  error: null,
  products: null,
};
const bestseller = {
  loading: false,
  error: null,
  product: null,
};
const deletedProduct = {
  loading: false,
  error: null,
  product: null,
};

const productsDropDownValues = {
  loading: false,
  error: null,
  dropDownValues: null,
};

const brands = {
  loading: false,
  error: null,
  brands: null,
};

const brandDetails = {
  loading: false,
  error: null,
  brandDetails: null,
};

const trendBrandDetails = {
  loading: false,
  error: null,
  trendBrandDetails: null,
};

const loadMore = {
  visibleCount: 20,
  lastViewedProductId: null,
};

const loadmoreReducer = (state = loadMore, action) => {
  switch (action.type) {
    case "SET_VISIBLE_COUNT":
      return {
        ...state,
        visibleCount: action.payload,
      };
    case "SET_LAST_VIEWED_PRODUCT":
      return {
        ...state,
        lastViewedProductId: action.payload,
      };
    default:
      return state;
  }
};

const gender = {
  gender: localStorage.getItem("gender") || "",
};

const genderReducer = (state = gender, action) => {
  switch (action.type) {
    case "SET_GENDER":
      return { ...state, gender: action.payload };
    default:
      return state;
  }
};
const addProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_PRODUCT_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
        productAdded: false,
      };
    case "ADD_PRODUCT_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        productAdded: true,
        productData: action.payload,
      };
    case "ADD_PRODUCT_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
        productAdded: false,
      };
    default:
      return state;
  }
};
const convertToPdfReducer = (state = arrayBuffer, action) => {
  switch (action.type) {
    case "ADD_ARRAYBUFFER_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "ADD_ARRAYBUFFER_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload,
      };
    case "ADD_ARRAYBUFFER_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
const getAllProductsReducer = (state = allProduct, action) => {
  switch (action.type) {
    case "FETCH_ALL_PRODUCT_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "FETCH_ALL_PRODUCT_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        allProducts: action.payload,
      };
    case "FETCH_ALL_PRODUCT_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const getProductReducer = (state = product, action) => {
  switch (action.type) {
    case "FETCH_PRODUCT_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "FETCH_PRODUCT_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        product: action.payload,
      };
    case "FETCH_PRODUCT_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
const getProductsReducer = (state = products, action) => {
  switch (action.type) {
    case "FETCH_PRODUCTS_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "FETCH_PRODUCTS_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        products: action.payload,
      };
    case "FETCH_PRODUCTS_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const deleteProductReducer = (state = deletedProduct, action) => {
  switch (action.type) {
    case "DELETE_PRODUCT_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "DELETE_PRODUCT_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        product: action.payload,
      };
    case "DELETE_PRODUCT_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const getProductsDropDownValuesReducer = (
  state = productsDropDownValues,
  action
) => {
  switch (action.type) {
    case "FETCH_PRODUCT_DROPDOWNVALUES_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "FETCH_PRODUCT_DROPDOWNVALUES_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        dropDownValues: action.payload,
      };
    case "FETCH_PRODUCT_DROPDOWNVALUES_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const getTrendsBrandReducer = (state = brands, action) => {
  switch (action.type) {
    case "FETCH_TREND_BRAND_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "FETCH_TREND_BRAND_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        brands: action.payload,
      };
    case "FETCH_TREND_BRAND_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const getBrandDetailsReducer = (state = brandDetails, action) => {
  switch (action.type) {
    case "FETCH_BRAND_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "FETCH_BRAND_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        brandDetails: action.payload,
      };
    case "FETCH_BRAND_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const getTrendBrandDetailsReducer = (state = trendBrandDetails, action) => {
  switch (action.type) {
    case "FETCH_TREND_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "FETCH_TREND_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        trendBrandDetails: action.payload,
      };
    case "FETCH_TREND_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const getBestSellerReducer = (state = bestseller, action) => {
  switch (action.type) {
    case "FETCH_BESTSELLER_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "FETCH_BESTSELLER_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        product: action.payload,
      };
    case "FETCH_BESTSELLER_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  addProductReducer,
  getAllProductsReducer,
  getProductReducer,
  deleteProductReducer,
  getProductsDropDownValuesReducer,
  getTrendsBrandReducer,
  getBestSellerReducer,
  genderReducer,
  getProductsReducer,
  convertToPdfReducer,
  loadmoreReducer,
  getBrandDetailsReducer,
  getTrendBrandDetailsReducer,
};
