import { server } from "../store";
import axios from "axios";

export const addUser = (userdetails) => async (dispatch) => {
  try {
    dispatch({ type: "ADD_USER_REQUEST" });
    const { data } = await axios.post(`${server}/user/add-user`, userdetails, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    console.log(data);
    dispatch({ type: "ADD_USER_SUCCESS", payload: data });
    return { success: true, message: "User added successfully" };
  } catch (error) {
    const errorMessage =
      error.response?.data?.message ||
      "An error occurred while adding the user.";
    dispatch({ type: "ADD_USER_FAILED", payload: errorMessage });
    return { success: false, message: errorMessage };
  }
};

export const editUser = (userdetails) => async (dispatch) => {
  try {
    dispatch({ type: "EDIT_USER_REQUEST" });
    const { data } = await axios.put(`${server}/user/edit-user`, userdetails, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    console.log(data);
    dispatch({ type: "EDIT_USER_SUCCESS", payload: data });
    return { success: true, message: "User edited successfully" };
  } catch (error) {
    const errorMessage =
      error.response?.data?.message ||
      "An error occurred while editing the user.";
    dispatch({ type: "EDIT_USER_FAILED", payload: errorMessage });
    return { success: false, message: errorMessage };
  }
};


export const deleteUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: "DELETE_USER_REQUEST" });
    const { data } = await axios.post(`${server}/user/delete-user?id=${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    console.log(data);
    dispatch({ type: "DELETE_USER_SUCCESS", payload: data });
    return true;
  } catch (error) {
    dispatch({
      type: "DELETE_USER_FAILED",
      payload: error.response.data.message,
    });
    return false;
  }
};

export const fetchUser = (userId) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_USER_REQUEST" });
    const { data } = await axios.get(`${server}/user/user?userId=${userId}`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    console.log(data);
    dispatch({ type: "FETCH_USER_SUCCESS", payload: data });
    return true;
  } catch (error) {
    dispatch({
      type: "FETCH_USER_FAILED",
      payload: error.response.data.message,
    });
    return false;
  }
};
export const fetchUserDetails = (userId, role) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_USERS_REQUEST" });
    const { data } = await axios.get(
      `${server}/user/all-users?userId=${userId}&role=${role}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    console.log(data);
    dispatch({ type: "FETCH_USERS_SUCCESS", payload: data });
    return true;
  } catch (error) {
    dispatch({
      type: "FETCH_USERS_FAILED",
      payload: error.response?.data?.message || "Something went wrong",
    });
    return false;
  }
};

