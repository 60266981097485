import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import Resizer from "react-image-file-resizer";
import {
  addProduct,
  getProductsDropDownValues,
  addNewSeason,
} from "../../redux/action/addProduct";
import arrow from "./icons/arrow.svg";
import { Toaster, toast } from "react-hot-toast";
import CircularProgress from "@mui/material/CircularProgress";

import { ExpandMore } from "@mui/icons-material";
import "./Product.css";
import exit from "../../images/icons/exit.svg";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox } from "@mui/material";
import addfile from "./icons/addfiles.svg";
import pdf from "./icons/pdf.svg";
import image from "./icons/image.svg";
import remove from "./icons/remove.svg";
import change from "./icons/change.svg";
import { useNavigate } from "react-router-dom";
import smallImage from "./icons/image-small.svg";
import { MdClose } from "react-icons/md";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import arrowGif from "../../images/icons/arrow.gif";
import { server } from "../../redux/store";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { backend_url } from "../../BaseUrl";
import { getUserDetailsFromLocalStorage } from "../LocalStorage";
const Product = ({
  getProductsDropDownValues,
  dropdownValues,
  addNewSeason,
  productLoading,
}) => {
  const [formData, setFormData] = useState({
    description: "",
    product_style: "",
    season: "",
    gender: "",
    fabric_ref: "",
    content: "",
    construction: "",
    categories: "",
    fabric_supplier: "",
    shrinkage: "",
    weight_unit: "",
    weight: "",
    cw: "",
    cw_unit: "",
    front_image: null,
    back_image: null,
    closeup_image1: null,
    closeup_image2: null,
    closeup_image3: null,
    phase: "Production",
    fabric_currency: "",
    fabric_price: "",
    yardage: "",
    garment_currency: "",
    garment_fob: "",
    best_seller: false,
    order_quantity: "",
    documents: [],
    division: "",
    customer: "",
    shipment_date: dayjs(new Date()),
  });
  const initialData = {
    description: "",
    product_style: "",
    season: "",
    gender: "",
    fabric_ref: "",
    content: "",
    construction: "",
    categories: "",
    fabric_supplier: "",
    shrinkage: "",
    weight_unit: "",
    weight: "",
    cw: "",
    cw_unit: "",
    front_image: null,
    back_image: null,
    closeup_image1: null,
    closeup_image2: null,
    closeup_image3: null,
    phase: "Production",
    fabric_currency: "",
    fabric_price: "",
    yardage: "",
    garment_currency: "",
    garment_fob: "",
    best_seller: false,
    order_quantity: "",
    documents: [],
    division: "",
    customer: "",
    shipment_date: dayjs(new Date()),
  };
  const navigate = useNavigate();
  const [hoverStates, setHoverStates] = useState({
    front_image: false,
    back_image: false,
    closeup_image1: false,
    closeup_image2: false,
    closeup_image3: false,
    // Add more images as needed
  });
  const [newItem, setNewItem] = useState("");
  const [open, setOpen] = useState(false);
  const [inputMode, setInputMode] = useState(false);
  const [files, setFiles] = useState([]);
  console.log("files", files);
  const [backIsHovered, setbackIsHovered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const resizeFile = (file) => {
    Resizer.imageFileResizer(
      file,
      1920, // new image max width
      1080, // new image max height
      "JPEG", // default type
      100, // new image quality
      0, // rotation degree
      (uri) => {
        console.log(uri); // resized new image uri
      },
      "file" // output type
    );
  };
  const [openExit, setOpenExit] = useState(false);
  const [datePickerFocused, setDatePickerFocused] = useState(false);
  useEffect(() => {
    getProductsDropDownValues();
    const userDetails = getUserDetailsFromLocalStorage();
    setId(userDetails?.user?._id);
  }, [getProductsDropDownValues]);

  useEffect(() => {
    if (inputMode) {
      const textField = document.getElementById("new-item");
      if (textField) {
        textField.focus(); // Focus on the TextField if it exists
      }
    }
  }, [inputMode]);

  // Usage:

  const handleMouseEnter = (image) => {
    setHoverStates((prevState) => ({
      ...prevState,
      [image]: true,
    }));
  };

  const handleMouseLeave = (image) => {
    setHoverStates((prevState) => ({
      ...prevState,
      [image]: false,
    }));
  };
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleChange = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;

      if (name === "order_quantity") {
        const formattedValue = value
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        setFormData((prevData) => ({
          ...prevData,
          [name]: formattedValue,
        }));
      } else if (
        name === "fob" ||
        name === "garment_fob" ||
        name === "fabric_price" ||
        name === "weight" ||
        name === "cw"
      ) {
        const formattedValue = value
          .replace(/[^\d.]/g, "") // Allow digits and period for decimals
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        setFormData((prevData) => ({
          ...prevData,
          [name]: formattedValue,
        }));
      } else {
        // For other fields, update the form data as usual
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else {
      console.error("Invalid event object:", e);
    }
  };

  const handleAddItem = async (e) => {
    e.preventDefault();
    if (newItem.trim() !== "") {
      // setSeasons((prevSeasons) => [...prevSeasons, newItem.trim()]);
      await addNewSeason(newItem.trim());
      setNewItem("");
      setInputMode(false);
      getProductsDropDownValues();
      setFormData((prevFormData) => ({
        ...prevFormData,
        season: newItem.trim(),
      }));
    }
  };

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddItem(e);
    }
  };
  const handleBlur = () => {
    if (inputMode) {
      setInputMode(false);
    }
  };
  console.log("formdata", formData);

  const handleFileChange = async (e) => {
    const selectedFiles = Array.from(e.target.files).map((file) => {
      const newFileName = file.name.replace(/\s+/g, "");
      return new File([file], newFileName, { type: file.type });
    });

    const name = e.target.name;
    if (name === "documents") {
      const fileNames = formData.documents.map((file) =>
        file.name.replace(/\s+/g, "")
      );

      const isFileNameExists = selectedFiles.some((file) =>
        fileNames.includes(file.name.replace(/\s+/g, ""))
      );

      if (isFileNameExists) {
        e.target.value = null;
        toast.error("File with the same name already exists!");
        return;
      }

      setFormData((prevData) => ({
        ...prevData,
        documents: [...prevData.documents, ...selectedFiles],
      }));
      e.target.value = null;
    } else {
      const selectedFileName = selectedFiles[0].name.replace(/\s+/g, "");
      const selectedFileNameWithoutExtension = selectedFileName
        .split(".")
        .slice(0, -1)
        .join(".");

      const isFileNameExists = files.some((file) => {
        const fileNameWithoutExtension = Object.values(file)[0]
          .replace(/\s+/g, "")
          .split(".")
          .slice(0, -1)
          .join(".");
        return fileNameWithoutExtension === selectedFileNameWithoutExtension;
      });

      console.log("files", files);
      console.log("selectedFile", selectedFileName);

      if (isFileNameExists) {
        e.target.value = null;
        toast.error("File with the same name already exists!");
        return;
      }

      try {
        console.log("Resizing image...");
        Resizer.imageFileResizer(
          selectedFiles[0],
          1920, // new image max width
          1080, // new image max height
          selectedFiles[0].type.split("/")[1], // default type
          100, // new image quality
          0, // rotation degree
          (uri) => {
            console.log("Resized image URI", uri);

            setFormData((prevData) => ({
              ...prevData,
              [name]: uri,
            }));

            // Add the form field name and URI name to the files state array
            setFiles((prevFiles) => [
              ...prevFiles,
              { [name]: uri.name.replace(/\s+/g, "") },
            ]);
          },
          "file" // output type
        );
      } catch (error) {
        console.log(error);
        toast.error("Failed to resize the image!");
      }
    }
  };

  const closeup_images = [
    formData.closeup_image1,
    formData.closeup_image2,
    formData.closeup_image3,
  ];
  const updatedDocs = closeup_images.filter(
    (obj) => obj && typeof obj === "object" && "name" in obj && "size" in obj
  );
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let missingField = null; // Variable to store the first missing field

      // Check if any required field is empty
      if (!formData.description) {
        missingField = "Description";
      } else if (!formData.season) {
        missingField = "Season";
      } else if (!formData.product_style) {
        missingField = "Product Style";
      } else if (!formData.gender) {
        missingField = "Gender";
      } else if (!formData.categories) {
        missingField = "Categories";
      } else if (!formData.fabric_ref) {
        missingField = "Fabric Reference";
      } else if (!formData.content) {
        missingField = "Content";
      } else if (!formData.construction) {
        missingField = "Construction";
      } else if (!formData.fabric_supplier) {
        missingField = "Fabric Supplier";
      } else if (!formData.shrinkage) {
        missingField = "Shrinkage";
      } else if (!formData.phase) {
        missingField = "Phase";
      } else if (!formData.weight) {
        missingField = "Weight";
      } else if (!formData.weight_unit) {
        missingField = "Weight Unit";
      } else if (!formData.cw) {
        missingField = "CW";
      } else if (!formData.cw_unit) {
        missingField = "CW Unit";
      } else if (!formData.fabric_currency) {
        missingField = "Fabric Currency";
      } else if (!formData.fabric_price) {
        missingField = "Fabric Price";
      } else if (!formData.shipment_date) {
        missingField = "Shipment Date";
      } else if (!formData.front_image) {
        missingField = "Front Image";
      } else if (!formData.back_image) {
        missingField = "Back Image";
      } else if (!formData.customer) {
        missingField = "Customer";
      } else if (!formData.division) {
        missingField = "Division";
      } else if (!formData.yardage) {
        missingField = "Yardage";
      }

      if (missingField) {
        toast.error(`${missingField} is required. Please fill in this field.`);
        return; // Exit function early if any required field is empty
      }

      const form = new FormData();
      form.append("createdby", id);
      form.append("description", formData.description);
      form.append("season", formData.season);
      form.append("product_style", formData.product_style);
      form.append("gender", formData.gender);
      form.append("categories", formData.categories);
      form.append("fabric_ref", formData.fabric_ref);
      form.append("content", formData.content);
      form.append("construction", formData.construction);
      form.append("yardage", formData.yardage);
      form.append("fabric_supplier", formData.fabric_supplier);
      form.append("shrinkage", formData.shrinkage);
      form.append("phase", formData.phase);
      form.append("weight", formData.weight + " " + formData.weight_unit);
      form.append("cw", formData.cw + " " + formData.cw_unit);
      form.append("fabric_currency", formData.fabric_currency);
      form.append("fabric_price", formData.fabric_price);

      if (formData.phase === "Development") {
        form.append("order_quantity", ""); // Use empty string if order_quantity is undefined
        form.append("garment_currency", "");
        form.append("garment_fob", ""); // Use empty string if garment_fob is undefined
      } else {
        form.append("order_quantity", formData.order_quantity);
        form.append("garment_currency", formData.garment_currency);
        form.append("garment_fob", formData.garment_fob);
      }

      form.append("best_seller", formData.best_seller);
      form.append("customer", formData.customer);
      form.append("division", formData.division);
      form.append("shipment_date", formData.shipment_date);

      if (formData.front_image) {
        form.append("front_image", formData.front_image);
      }
      if (formData.back_image) {
        form.append("back_image", formData.back_image);
      }

      if (updatedDocs) {
        updatedDocs.forEach((image, index) => {
          form.append("closeup_images", image);
        });
      }

      formData.documents.forEach((image, index) => {
        form.append("documents", image);
      });

      setLoading(true);
      await axios
        .post(`${backend_url}/product/add-product`, form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data); // Log the response data for debugging
          toast.success("Product created successfully!");
        });
      setFormData({
        description: "",
        product_style: "",
        season: "",
        gender: "",
        fabric_ref: "",
        content: "",
        construction: "",
        categories: "",
        fabric_supplier: "",
        shrinkage: "",
        weight_unit: "",
        weight: "",
        cw: "",
        cw_unit: "",
        front_image: null,
        back_image: null,
        closeup_image1: null,
        closeup_image2: null,
        closeup_image3: null,
        phase: "Production",
        fabric_currency: "",
        fabric_price: "",
        yardage: "",
        garment_currency: "",
        garment_fob: "",
        best_seller: false,
        order_quantity: "",
        documents: [],
        division: "",
        customer: "",
        shipment_date: dayjs(new Date()),
      });
    } catch (error) {
      console.error("Error creating product:", error);
      toast.error(
        `${
          error.response?.data?.message || error.message
        }. Please try again.`
      );
    } finally {
      setLoading(false); // Set loading back to false after receiving the response or encountering an error
    }
  };
  const GradientCircularProgress = () => (
    <div className="loading-container">
      <React.Fragment>
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#26408A" />{" "}
              {/* Change color to #26408A */}
              <stop offset="50%" stopColor="#196D92" />{" "}
              {/* Change color to #196D92 */}
              <stop offset="100%" stopColor="#49883E" />{" "}
              {/* Change color to #49883E */}
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress
          sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
        />
      </React.Fragment>
    </div>
  );

  if (loading) {
    return (
      <div>
        <GradientCircularProgress />
      </div>
    );
  }
  const MenuProps = {
    PaperProps: {
      sx: {
        "& .MuiMenu-list": {
          paddingTop: "0px !important",
          paddingBottom: "0px !important",
          maxHeight: "400px",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "0px",
          },
        },
      },
    },
  };
  const handleRemoveFile = (indexToRemove) => {
    setFormData((prevData) => ({
      ...prevData,
      documents: prevData.documents.filter(
        (_, index) => index !== indexToRemove
      ),
    }));
  };

  const handleReplaceFrontImage = (e) => {
    const selectedFiles = Array.from(e.target.files);

    const selectedFileName = e.target.files[0].name;
    const selectedFileNameWithoutExtension = selectedFileName
      .split(".")
      .slice(0, -1)
      .join(".");

    const isFileNameExists = files.some((fileName) => {
      const fileNameWithoutExtension = Object.values(fileName)[0]
        .split(".")
        .slice(0, -1)
        .join(".");
      return fileNameWithoutExtension === selectedFileNameWithoutExtension;
    });

    if (isFileNameExists) {
      e.target.value = null;
      toast.error("File with the same name already exists!");
      return;
    }

    const updatedFiles = files.filter(
      (file) => Object.keys(file)[0] !== "front_image"
    );
    setFiles(updatedFiles);
    try {
      console.log("Resizing image...");
      Resizer.imageFileResizer(
        selectedFiles[0],
        1920, // new image max width
        1080, // new image max height
        selectedFiles[0].type.split("/")[1], // default type
        100, // new image quality
        0, // rotation degree
        (uri) => {
          console.log("Resized image URI", uri);

          setFormData((prevData) => ({
            ...prevData,
            front_image: uri,
          }));

          // Add the form field name and URI name to the files state array
          setFiles((prevFiles) => [...prevFiles, { front_image: uri.name }]);
        },
        "file" // output type
      );
    } catch (error) {
      console.log(error);
      toast.error("Failed to resize the image!");
    }
  };

  const handleRemoveFrontImage = () => {
    const updatedFiles = files.filter(
      (file) => Object.keys(file)[0] !== "front_image"
    );

    setFiles(updatedFiles);
    formData.front_image = null;
    hoverStates.front_image = false;
    const fileInput = document.getElementById("frontImage-replace");
    console.log("fileInput", fileInput);
    if (fileInput) {
      fileInput.value = null; // Reset the file input field
    }
  };

  const handleReplaceBackImage = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const selectedFileName = e.target.files[0].name;
    const selectedFileNameWithoutExtension = selectedFileName
      .split(".")
      .slice(0, -1)
      .join(".");

    const isFileNameExists = files.some((fileName) => {
      const fileNameWithoutExtension = Object.values(fileName)[0]
        .split(".")
        .slice(0, -1)
        .join(".");
      return fileNameWithoutExtension === selectedFileNameWithoutExtension;
    });

    if (isFileNameExists) {
      e.target.value = null;
      toast.error("File with the same name already exists!");
      return;
    }

    try {
      console.log("Resizing image...");
      Resizer.imageFileResizer(
        selectedFiles[0],
        1920, // new image max width
        1080, // new image max height
        selectedFiles[0].type.split("/")[1], // default type
        100, // new image quality
        0, // rotation degree
        (uri) => {
          console.log("Resized image URI", uri);

          setFormData((prevData) => ({
            ...prevData,
            back_image: uri,
          }));

          // Add the form field name and URI name to the files state array
          setFiles((prevFiles) => [...prevFiles, { back_image: uri.name }]);
        },
        "file" // output type
      );
    } catch (error) {
      console.log(error);
      toast.error("Failed to resize the image!");
    }
  };

  const handleRemoveBackImage = () => {
    const updatedFiles = files.filter(
      (file) => Object.keys(file)[0] !== "back_image"
    );

    setFiles(updatedFiles);
    formData.back_image = null;
    hoverStates.back_image = false;
    const fileInput = document.getElementById("backImage-replace");
    console.log("fileInput", fileInput);

    if (fileInput) {
      fileInput.value = null; // Reset the file input field
    }
  };

  const handleReplaceCloseImage1 = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const selectedFileName = e.target.files[0].name;
    const selectedFileNameWithoutExtension = selectedFileName
      .split(".")
      .slice(0, -1)
      .join(".");

    const isFileNameExists = files.some((fileName) => {
      const fileNameWithoutExtension = Object.values(fileName)[0]
        .split(".")
        .slice(0, -1)
        .join(".");
      return fileNameWithoutExtension === selectedFileNameWithoutExtension;
    });

    if (isFileNameExists) {
      e.target.value = null;
      toast.error("File with the same name already exists!");
      return;
    }

    const file = e.target.files[0];

    try {
      console.log("Resizing image...");
      Resizer.imageFileResizer(
        selectedFiles[0],
        1920, // new image max width
        1080, // new image max height
        selectedFiles[0].type.split("/")[1], // default type
        100, // new image quality
        0, // rotation degree
        (uri) => {
          console.log("Resized image URI", uri);

          setFormData((prevData) => ({
            ...prevData,
            closeup_image1: uri,
          }));

          // Add the form field name and URI name to the files state array
          setFiles((prevFiles) => [...prevFiles, { closeup_image1: uri.name }]);
        },
        "file" // output type
      );
    } catch (error) {
      console.log(error);
      toast.error("Failed to resize the image!");
    }
  };

  const handleRemoveCloseupImage1 = () => {
    const updatedFiles = files.filter(
      (file) => Object.keys(file)[0] !== "closeup_image1"
    );

    setFiles(updatedFiles);
    formData.closeup_image1 = null;
    hoverStates.closeup_image1 = false;
    const fileInput = document.getElementById("closeupImage1-replace");
    if (fileInput) {
      fileInput.value = null; // Reset the file input field
    }
  };

  const handleReplaceCloseImage2 = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const selectedFileName = e.target.files[0].name;
    const selectedFileNameWithoutExtension = selectedFileName
      .split(".")
      .slice(0, -1)
      .join(".");

    const isFileNameExists = files.some((fileName) => {
      const fileNameWithoutExtension = Object.values(fileName)[0]
        .split(".")
        .slice(0, -1)
        .join(".");
      return fileNameWithoutExtension === selectedFileNameWithoutExtension;
    });

    if (isFileNameExists) {
      e.target.value = null;
      toast.error("File with the same name already exists!");
      return;
    }

    const file = e.target.files[0];

    try {
      console.log("Resizing image...");
      Resizer.imageFileResizer(
        selectedFiles[0],
        1920, // new image max width
        1080, // new image max height
        selectedFiles[0].type.split("/")[1], // default type
        100, // new image quality
        0, // rotation degree
        (uri) => {
          console.log("Resized image URI", uri);

          setFormData((prevData) => ({
            ...prevData,
            closeup_image2: uri,
          }));

          // Add the form field name and URI name to the files state array
          setFiles((prevFiles) => [...prevFiles, { closeup_image2: uri.name }]);
        },
        "file" // output type
      );
    } catch (error) {
      console.log(error);
      toast.error("Failed to resize the image!");
    }
  };

  const handleRemoveCloseupImage2 = () => {
    const updatedFiles = files.filter(
      (file) => Object.keys(file)[0] !== "closeup_image2"
    );

    setFiles(updatedFiles);
    formData.closeup_image2 = null;
    hoverStates.closeup_image2 = false;
    const fileInput = document.getElementById("closeupImage2-replace");
    if (fileInput) {
      fileInput.value = null; // Reset the file input field
    }
  };

  const handleReplaceCloseImage3 = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const selectedFileName = e.target.files[0].name;
    const selectedFileNameWithoutExtension = selectedFileName
      .split(".")
      .slice(0, -1)
      .join(".");

    const isFileNameExists = files.some((fileName) => {
      const fileNameWithoutExtension = Object.values(fileName)[0]
        .split(".")
        .slice(0, -1)
        .join(".");
      return fileNameWithoutExtension === selectedFileNameWithoutExtension;
    });

    if (isFileNameExists) {
      e.target.value = null;
      toast.error("File with the same name already exists!");
      return;
    }

    const file = e.target.files[0];

    try {
      console.log("Resizing image...");
      Resizer.imageFileResizer(
        selectedFiles[0],
        1920, // new image max width
        1080, // new image max height
        selectedFiles[0].type.split("/")[1], // default type
        100, // new image quality
        0, // rotation degree
        (uri) => {
          console.log("Resized image URI", uri);

          setFormData((prevData) => ({
            ...prevData,
            closeup_image3: uri,
          }));

          // Add the form field name and URI name to the files state array
          setFiles((prevFiles) => [...prevFiles, { closeup_image3: uri.name }]);
        },
        "file" // output type
      );
    } catch (error) {
      console.log(error);
      toast.error("Failed to resize the image!");
    }
  };

  const handleRemoveCloseupImage3 = () => {
    const updatedFiles = files.filter(
      (file) => Object.keys(file)[0] !== "closeup_image3"
    );

    setFiles(updatedFiles);
    formData.closeup_image3 = null;
    hoverStates.closeup_image3 = false;
    const fileInput = document.getElementById("closeupImage3-replace");
    if (fileInput) {
      fileInput.value = null; // Reset the file input field
    }
  };

  const handleClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const extractDate = (dateString) => {
    return dayjs(dateString).format("YYYY-MM-DD");
  };

  const handleCancel = () => {
    const initialDataDate = extractDate(initialData.shipment_date);
    const formDataDate = extractDate(formData.shipment_date);

    const initialDataCopy = { ...initialData, shipment_date: initialDataDate };
    const formDataCopy = { ...formData, shipment_date: formDataDate };

    if (JSON.stringify(initialDataCopy) === JSON.stringify(formDataCopy)) {
      navigate(-1);
    } else {
      setOpenExit(true);
    }
  };

  const handleClose = () => {
    setOpenExit(false);
  };
  const handleViewProductClick = () => {
    navigate("/dashboard");
  };
  console.log("initialData", initialData);
  console.log("formData", formData);
  const handleOpen = () => {
    setDatePickerFocused(true);
  };

  const handleDateClose = () => {
    setDatePickerFocused(false);
  };

  console.log("status", datePickerFocused);

  const handleDateChange = (newDate) => {
    setFormData((prevState) => ({
      ...prevState,
      shipment_date: newDate,
    }));
  };

  return (
    <div>
      <Toaster />
      <Dialog
        open={openExit}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "502px",
            height: "333px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "32px 80px",
          },
        }}
        sx={{ "& .MuiDialogContent-root": { overflow: "hidden" } }}
      >
        <img src={exit} alt="" width={100} height={100} />
        <DialogTitle id="alert-dialog-title">
          {"Are You Sure You Want to Exit ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This Product is not Added.
            <br /> You Can’t Undo This Action.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{
              position: "relative",
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",
              fontWeight: 600,
              color: "#26408A",
              padding: "0.5rem 1rem",
              cursor: "pointer",
              width: "140px",
              height: "40px",
              borderRadius: "20px",
              border: "2px solid transparent",
              backgroundImage: `linear-gradient(white, white), linear-gradient(to right, #26408A, #196D92, #49883E)`,
              backgroundOrigin: "padding-box, border-box",
              backgroundClip: "padding-box, border-box",
              marginRight: "24px",
              textTransform: "capitalize",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleViewProductClick}
            autoFocus
            sx={{
              position: "relative",
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",
              fontWeight: 600,
              padding: "0.5rem 1rem",
              cursor: "pointer",
              width: "140px",
              height: "40px",
              borderRadius: "20px",
              border: "2px solid transparent",
              background:
                "linear-gradient(to right, #26408A, #196D92,#49883E) padding-box,linear-gradient(to right, #26408A, #196D92,#49883E) border-box",
              color: "#fff",
              textTransform: "capitalize",
              // "&:hover": {
              //   backgroundImage: `linear-gradient(white, white), linear-gradient(to right, #26408A, #196D92, #49883E)`,
              //   backgroundOrigin: "padding-box, border-box",
              //   backgroundClip: "padding-box, border-box",
              //   textTransform: "capitalize",
              //   background:
              //     "linear-gradient(to right, #26408A, #196D92,#49883E) padding-box,linear-gradient(to right, #26408A, #196D92,#49883E) border-box",
              //   color: "#fff",
              // },
            }}
          >
            Exit
          </Button>
        </DialogActions>
      </Dialog>
      <div className="addproduct-container">
        <div className="productdetails-functions">
          <div
            style={{ width: "300px", display: "flex", alignItems: "center" }}
          >
            {" "}
            <div>
              {!backIsHovered && (
                <img
                  src={arrow}
                  alt=""
                  width={32}
                  height={32}
                  className="backicon"
                  onClick={handleCancel}
                  onMouseEnter={() => setbackIsHovered(true)}
                  onMouseLeave={() => setbackIsHovered(false)}
                />
              )}
              {backIsHovered && (
                <img
                  src={arrowGif}
                  alt=""
                  width={32}
                  height={32}
                  className="backicon"
                  onClick={handleCancel}
                  onMouseEnter={() => setbackIsHovered(true)}
                  onMouseLeave={() => setbackIsHovered(false)}
                />
              )}
            </div>
            <div>
              <span className="addproduct-back-navigate">Add Product</span>
            </div>
          </div>
        </div>

        <div className="addproduct-form">
          <div>
            <span className="fabric-title">Fabric Info</span>
          </div>
          <div className="product-line"></div>
          <Box
            component="form"
            width="100%"
            alignItems="unset"
            noValidate
            autoComplete="off"
          >
            <div className="add-product-grid">
              <TextField
                id="outlined-multiline-flexible"
                name="description"
                label="Description"
                multiline
                maxRows={1}
                value={formData.description}
                onChange={handleChange}
                size="small"
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.8)",
                  },
                  "& .MuiInputLabel-root": {
                    color: "rgba(0, 0, 0, 0.8)",
                  },
                }}
              />
              <FormControl sx={{}} size="small">
                {!inputMode && (
                  <>
                    <InputLabel
                      htmlFor="demo-select-small"
                      sx={{
                        "&.MuiInputLabel-root": {
                          color: "rgba(0, 0, 0, 0.8)", // Change the label color to black
                        },
                      }}
                    >
                      Season
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      name="season"
                      label="Season"
                      value={formData.season} // Use empty string for value
                      onChange={handleChange}
                      open={open}
                      onClose={() => setOpen(false)}
                      onOpen={() => setOpen(true)}
                      IconComponent={ExpandMore}
                      MenuProps={MenuProps}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(0, 0, 0, 0.8)",
                        },
                        "& .MuiInputLabel-root": {
                          color: "rgba(0, 0, 0, 0.8)",
                        },
                        "& .MuiSelect-icon": {
                          // Select the icon specifically
                          color: "#1A1B21", // Set the color of the icon
                        },
                      }}
                    >
                      <MenuItem
                        onClick={() => setInputMode(true)}
                        sx={{
                          borderBottom: "1px solid #C6C6D0",
                          backgroundColor: "#FFF !important",
                          paddingTop: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          paddingRight: 0,
                        }}
                      >
                        <Button
                          variant="outlined"
                          sx={{
                            width: "100%",
                            justifyContent: "center",
                            color: "#26408A",
                            fontWeight: "700",
                            fontSize: "16px",
                            height: "40px",
                            border: "none",
                            fontFamily:
                              "Segoe UI, SegoeUI, Helvetica Neue, Helvetica, Arial, sans-serif",
                            backgroundColor: "#FFF",
                            textTransform: "none",
                          }}
                        >
                          Add New
                        </Button>
                      </MenuItem>
                      {dropdownValues?.data?.map((dropdown) =>
                        dropdown.seasons.map((season) => (
                          <MenuItem key={season} value={season}>
                            {season}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </>
                )}
                {inputMode && (
                  <TextField
                    id="new-item"
                    label="New Season"
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 0.8)", // Change the border color to black
                      },
                      "& .MuiInputLabel-root": {
                        color: "rgba(0, 0, 0, 0.8)", // Change the label color to black
                      },
                    }}
                    value={newItem}
                    onChange={(e) => setNewItem(e.target.value)}
                    onKeyPress={handleInputKeyPress}
                    onBlur={handleBlur}
                  />
                )}
              </FormControl>

              <TextField
                id="outlined-multiline-flexible"
                name="product_style"
                label="Style Name / Reference. No"
                multiline
                maxRows={1}
                value={formData.product_style}
                size="small"
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.8)", // Change the border color to black
                  },
                  "& .MuiInputLabel-root": {
                    color: "rgba(0, 0, 0, 0.8)", // Change the label color to black
                  },
                }}
                onChange={handleChange}
              />
              <FormControl sx={{}} size="small">
                <InputLabel
                  htmlFor="demo-select-small"
                  sx={{
                    "&.MuiInputLabel-root": {
                      color: "rgba(0, 0, 0, 0.8)", // Change the label color to black
                    },
                  }}
                >
                  Gender
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  name="gender"
                  value={formData.gender}
                  label="gender"
                  onChange={handleChange}
                  IconComponent={ExpandMore}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.8)", // Change the border color to black
                    },
                    "& .MuiSelect-icon": {
                      // Select the icon specifically
                      color: "#1A1B21", // Set the color of the icon
                    },
                  }}
                >
                  {dropdownValues?.data?.map((dropdown) =>
                    dropdown.gender.map((gender) => (
                      <MenuItem key={gender} value={gender}>
                        {gender}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>

              <FormControl sx={{}} size="small">
                <InputLabel
                  htmlFor="demo-categories-small"
                  sx={{
                    "&.MuiInputLabel-root": {
                      color: "rgba(0, 0, 0, 0.8)", // Change the label color to black
                    },
                  }}
                >
                  Categories
                </InputLabel>
                <Select
                  labelId="demo-categories-small"
                  id="demo-categories-small"
                  name="categories"
                  value={formData.categories}
                  label="Categories"
                  onChange={handleChange}
                  IconComponent={ExpandMore}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.8)", // Change the border color to black
                    },
                    "& .MuiSelect-icon": {
                      // Select the icon specifically
                      color: "#1A1B21", // Set the color of the icon
                    },
                  }}
                >
                  {dropdownValues?.data?.map((dropdown) =>
                    dropdown.categories.map((categories) => (
                      <MenuItem key={categories} value={categories}>
                        {categories}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>

              <TextField
                id="outlined-multiline-flexible"
                name="fabric_ref"
                label="Fabric Reference. No"
                multiline
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.8)", // Change the border color to black
                  },
                  "& .MuiInputLabel-root": {
                    color: "rgba(0, 0, 0, 0.8)", // Change the label color to black
                  },
                }}
                maxRows={1}
                value={formData.fabric_ref}
                size="small"
                onChange={handleChange}
              />
              <TextField
                id="outlined-multiline-flexible"
                name="content"
                label="Content"
                multiline
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.8)", // Change the border color to black
                  },
                  "& .MuiInputLabel-root": {
                    color: "rgba(0, 0, 0, 0.8)", // Change the label color to black
                  },
                }}
                maxRows={1}
                value={formData.content}
                size="small"
                onChange={handleChange}
              />
              <TextField
                id="outlined-multiline-flexible"
                name="construction"
                label="Construction"
                multiline
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.8)",
                  },
                  "& .MuiInputLabel-root": {
                    color: "rgba(0, 0, 0, 0.8)",
                  },
                }}
                maxRows={1}
                value={formData.construction}
                size="small"
                onChange={handleChange}
              />
              <TextField
                id="outlined-multiline-flexible"
                name="fabric_supplier"
                label="Fabric Mill / Supplier"
                multiline
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.8)",
                  },
                  "& .MuiInputLabel-root": {
                    color: "rgba(0, 0, 0, 0.8)",
                  },
                }}
                maxRows={1}
                value={formData.fabric_supplier}
                size="small"
                onChange={handleChange}
              />
              <TextField
                id="outlined-multiline-flexible"
                name="shrinkage"
                label="Shrinkage"
                multiline
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.8)",
                  },
                  "& .MuiInputLabel-root": {
                    color: "rgba(0, 0, 0, 0.8)",
                  },
                }}
                maxRows={1}
                value={formData.shrinkage}
                size="small"
                onChange={handleChange}
              />
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: "20px",
                }}
              >
                {" "}
                <FormControl size="small">
                  <InputLabel
                    htmlFor="demo-select-small"
                    sx={{
                      "&.MuiInputLabel-root": {
                        color: "rgba(0, 0, 0, 0.8)",
                      },
                    }}
                  >
                    Weight - Unit
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    name="weight_unit"
                    value={formData.weight_unit}
                    label="Weight - Unit"
                    onChange={handleChange}
                    IconComponent={ExpandMore}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 0.8)", // Change the border color to black
                      },
                      "& .MuiSelect-icon": {
                        // Select the icon specifically
                        color: "#1A1B21", // Set the color of the icon
                      },
                    }}
                  >
                    {dropdownValues?.data?.map((dropdown) =>
                      dropdown.weight_units.map((units) => (
                        <MenuItem key={units} value={units}>
                          {units}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
                <TextField
                  id="outlined-multiline-flexible"
                  name="weight"
                  label="Weight"
                  multiline
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.8)", // Change the border color to black
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(0, 0, 0, 0.8)", // Change the label color to black
                    },
                  }}
                  maxRows={1}
                  value={formData.weight}
                  size="small"
                  onChange={handleChange}
                />
              </div>

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: "20px",
                }}
              >
                <FormControl size="small">
                  <InputLabel
                    htmlFor="demo-select-small"
                    sx={{
                      "&.MuiInputLabel-root": {
                        color: "rgba(0, 0, 0, 0.8)", // Change the label color to black
                      },
                    }}
                  >
                    CW - Unit
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    name="cw_unit"
                    value={formData.cw_unit}
                    label="CW - Unit"
                    onChange={handleChange}
                    IconComponent={ExpandMore}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 0.8)", // Change the border color to black
                      },
                      "& .MuiSelect-icon": {
                        // Select the icon specifically
                        color: "#1A1B21", // Set the color of the icon
                      },
                    }}
                  >
                    {dropdownValues?.data?.map((dropdown) =>
                      dropdown.cw_units.map((units) => (
                        <MenuItem key={units} value={units}>
                          {units}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
                <TextField
                  id="outlined-multiline-flexible"
                  name="cw"
                  label="CW"
                  multiline
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.8)", // Change the border color to black
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(0, 0, 0, 0.8)", // Change the label color to black
                    },
                  }}
                  maxRows={1}
                  value={formData.cw}
                  size="small"
                  onChange={handleChange}
                />
              </div>

              <FormControl size="small">
                <InputLabel
                  htmlFor="demo-select-small"
                  sx={{
                    "&.MuiInputLabel-root": {
                      color: "rgba(0, 0, 0, 0.8)",
                    },
                  }}
                >
                  Fabric Currency
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  name="fabric_currency"
                  value={formData.fabric_currency}
                  label="Fabric Currency"
                  onChange={handleChange}
                  IconComponent={ExpandMore}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.8)", // Change the border color to black
                    },
                    "& .MuiSelect-icon": {
                      // Select the icon specifically
                      color: "#1A1B21", // Set the color of the icon
                    },
                  }}
                >
                  {dropdownValues?.data?.map((dropdown) =>
                    dropdown.currency.map((units) => (
                      <MenuItem key={units} value={units}>
                        {units}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
              <TextField
                id="outlined-multiline-flexible"
                name="fabric_price"
                label={`Fabric Price (${formData.fabric_currency || "USD"})`}
                multiline
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.8)", // Change the border color to black
                  },
                  "& .MuiInputLabel-root": {
                    color: "rgba(0, 0, 0, 0.8)", // Change the label color to black
                  },
                }}
                maxRows={1}
                value={formData.fabric_price}
                size="small"
                onChange={handleChange}
              />
              <FormControl size="small">
                <InputLabel
                  htmlFor="demo-select-small"
                  sx={{
                    "&.MuiInputLabel-root": {
                      color: "rgba(0, 0, 0, 0.8)",
                    },
                  }}
                >
                  Yardage
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  name="yardage"
                  value={formData.yardage}
                  label="Yardage"
                  onChange={handleChange}
                  IconComponent={ExpandMore}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.8)", // Change the border color to black
                    },
                    "& .MuiSelect-icon": {
                      // Select the icon specifically
                      color: "#1A1B21", // Set the color of the icon
                    },
                  }}
                >
                  {dropdownValues?.data?.map((dropdown) =>
                    dropdown.yardage.map((units) => (
                      <MenuItem key={units} value={units}>
                        {units}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </div>
          </Box>

          <span className="fabric-title">Phase</span>
          <div className="product-line2"></div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="radio-buttons-group"
                value={formData.phase}
                onChange={handleChange}
                name="radio-buttons-group"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <FormControlLabel
                  name="phase"
                  value="Production"
                  control={
                    <Radio
                      style={{
                        color:
                          formData.phase === "Production"
                            ? "#26408A"
                            : "#45464F",
                      }}
                    />
                  } // Custom color for the radio button
                  label="Production"
                  style={{
                    color:
                      formData.phase === "Production" ? "#26408A" : "#45464F",
                    marginRight: "20px",
                  }} // Custom color for the label
                />
                <FormControlLabel
                  name="phase"
                  value="Development"
                  control={
                    <Radio
                      style={{
                        color:
                          formData.phase === "Development"
                            ? "#26408A"
                            : "#45464F",
                      }}
                    />
                  } // Custom color for the radio button
                  label="Development"
                  style={{
                    color:
                      formData.phase === "Development" ? "#26408A" : "#45464F",
                  }} // Custom color for the label
                />
              </RadioGroup>
            </FormControl>
          </div>

          <Box
            component="form"
            width="100%"
            alignItems="unset"
            noValidate
            autoComplete="off"
          >
            <div className="add-product-grid">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={
                    formData.phase === "Production"
                      ? "Shipment Date"
                      : "Development Date"
                  }
                  name="shipment_date"
                  value={formData.shipment_date}
                  onChange={handleDateChange}
                  format="DD MMM YYYY" // Specify the date format here
                  onOpen={handleOpen}
                  onClose={handleDateClose}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{ ...params.InputProps }}
                    />
                  )}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      height: "0.4375em",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.8)", // Change the border color to black
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(0, 0, 0, 0.8)", // Change the label color to black
                      marginTop: datePickerFocused
                        ? "0px"
                        : formData.shipment_date === null
                        ? "-7px"
                        : "0px",
                    },
                  }}
                />
              </LocalizationProvider>

              {formData.phase === "Production" && (
                <>
                  <TextField
                    id="outlined-multiline-flexible"
                    name="order_quantity"
                    label="Order Quantity (Pcs)"
                    multiline
                    maxRows={1}
                    value={formData.order_quantity}
                    onChange={handleChange}
                    size="small"
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 0.8)", // Change the border color to black
                      },
                      "& .MuiInputLabel-root": {
                        color: "rgba(0, 0, 0, 0.8)", // Change the label color to black
                      },
                    }}
                  />
                  <FormControl size="small">
                    <InputLabel
                      htmlFor="demo-select-small"
                      sx={{
                        "&.MuiInputLabel-root": {
                          color: "rgba(0, 0, 0, 0.8)",
                        },
                      }}
                    >
                      Garment Currency
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      name="garment_currency"
                      value={formData.garment_currency}
                      label="Garment Currency"
                      onChange={handleChange}
                      IconComponent={ExpandMore}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(0, 0, 0, 0.8)", // Change the border color to black
                        },
                        "& .MuiSelect-icon": {
                          // Select the icon specifically
                          color: "#1A1B21", // Set the color of the icon
                        },
                      }}
                    >
                      {dropdownValues?.data?.map((dropdown) =>
                        dropdown.currency.map((units) => (
                          <MenuItem key={units} value={units}>
                            {units}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </FormControl>
                  <TextField
                    id="outlined-multiline-flexible"
                    name="garment_fob"
                    label={`Garment FOB (${
                      formData.garment_currency || "USD"
                    })`}
                    multiline
                    maxRows={1}
                    value={formData.garment_fob}
                    onChange={handleChange}
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 0.8)", // Change the border color to black
                      },
                      "& .MuiInputLabel-root": {
                        color: "rgba(0, 0, 0, 0.8)", // Change the label color to black
                      },
                    }}
                  />
                </>
              )}
              <TextField
                id="outlined-multiline-flexible"
                name="customer"
                label="Customer"
                multiline
                maxRows={1}
                value={formData.customer}
                onChange={handleChange}
                size="small"
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.8)",
                  },
                  "& .MuiInputLabel-root": {
                    color: "rgba(0, 0, 0, 0.8)",
                  },
                }}
              />
              <TextField
                id="outlined-multiline-flexible"
                name="division"
                label="Division"
                multiline
                maxRows={1}
                value={formData.division}
                onChange={handleChange}
                size="small"
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.8)",
                  },
                  "& .MuiInputLabel-root": {
                    color: "rgba(0, 0, 0, 0.8)",
                  },
                }}
              />
            </div>
          </Box>
          {formData.phase === "Production" && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.best_seller}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          best_seller: e.target.checked,
                        })
                      }
                      style={{
                        color: formData.best_seller ? "#26408A" : "#45464F",
                      }}
                    />
                  }
                  style={{
                    color: formData.best_seller ? "#26408A" : "#45464F",
                    marginRight: 0,
                  }}
                />
                <span>Is This Product a Best Seller?</span>
              </div>

              <input
                type="file"
                name="documents"
                id="Input"
                accept=".pdf,.jpg,.jpeg,.png,.doc,.docx"
                style={{ display: "none" }}
                onChange={handleFileChange}
                multiple
                ref={fileInputRef}
              />
              <button
                onClick={handleButtonClick}
                style={{
                  width: "100%",
                  height: "48px",
                  backgroundColor: "#e5f1ff",
                  border: "1px dashed #26408A",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#26408A",
                  fontSize: "16px",
                  fontWeight: "bold",
                  cursor: "pointer",
                  marginBottom: "10px",
                }}
              >
                <img
                  src={addfile}
                  alt="addfile"
                  width={25}
                  style={{ marginRight: "8px" }}
                />
                <span>Attach Documents</span>
              </button>

              <div
                className={
                  formData.documents.length === 0
                    ? "addproduct-upload-files"
                    : "upload-file"
                }
              >
                {formData.documents.map((file, index) => (
                  <div key={index} className="email-file">
                    <div className="file-info">
                      <img src={pdf} alt="file" width={16} />
                      <p>{file.name}</p>
                    </div>
                    <div onClick={() => handleRemoveFile(index)}>
                      <MdClose />
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
          <div style={{ marginBottom: "15px" }}>
            <span className="fabric-title">Image</span>
          </div>

          <div className="addproduct-images-container">
            {!formData.front_image ? (
              <label htmlFor="frontImageInput" className="upload-button">
                <input
                  type="file"
                  id="frontImageInput"
                  name="front_image"
                  accept=".jpg,.jpeg,.png,.webp"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <div className="addproduct-square-box">
                  <div className="add-images">
                    <img src={image} alt="" className="iamge-size" />
                    <span className="box-text">Add Front Image</span>
                  </div>
                </div>
              </label>
            ) : (
              <div className="select-square-box-wrapper">
                <div
                  className="selected-square-box"
                  onMouseEnter={() => handleMouseEnter("front_image")}
                  onMouseLeave={() => handleMouseLeave("front_image")}
                >
                  <div className="selected-images">
                    <img
                      src={URL.createObjectURL(formData.front_image)}
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="image-card-footer">
                    <img src={smallImage} alt="" width={24} height={24} />
                    <span className="image-footer-text">Front Image</span>
                  </div>
                </div>

                {hoverStates.front_image && (
                  <div
                    className="addproduct-hover-content"
                    onMouseEnter={() => handleMouseEnter("front_image")}
                    onMouseLeave={() => handleMouseLeave("front_image")}
                  >
                    <label
                      htmlFor="frontImage-replace"
                      className="image-button"
                    >
                      <img src={change} alt="" />
                      <span>Change Image</span>
                      <input
                        id="frontImage-replace"
                        type="file"
                        accept="image/*"
                        onChange={handleReplaceFrontImage}
                        style={{ display: "none" }}
                      />
                    </label>
                    <button
                      className="image-button"
                      onClick={handleRemoveFrontImage}
                    >
                      <img src={remove} alt="" />
                      <span>Remove Image</span>
                    </button>
                  </div>
                )}
              </div>
            )}
            {!formData.back_image ? (
              <label htmlFor="backImageInput" class="upload-button">
                <input
                  type="file"
                  id="backImageInput"
                  name="back_image"
                  accept=".jpg,.jpeg,.png,.webp"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <div class="addproduct-square-box">
                  <div class="add-images">
                    <img src={image} alt="" className="iamge-size" />
                    <span className="box-text">Add Back Image</span>
                  </div>
                </div>
              </label>
            ) : (
              <div className="select-square-box-wrapper">
                <div
                  className="selected-square-box"
                  onMouseEnter={() => handleMouseEnter("back_image")}
                  onMouseLeave={() => handleMouseLeave("back_image")}
                >
                  <div className="selected-images">
                    <img
                      src={URL.createObjectURL(formData.back_image)}
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="image-card-footer">
                    <img src={smallImage} alt="" width={24} height={24} />
                    <span className="image-footer-text">Back Image</span>
                  </div>
                </div>

                {hoverStates.back_image && (
                  <div
                    className="addproduct-hover-content"
                    onMouseEnter={() => handleMouseEnter("back_image")}
                    onMouseLeave={() => handleMouseLeave("back_image")}
                  >
                    <label htmlFor="backImage-replace" className="image-button">
                      <img src={change} alt="" />
                      <span>Change Image</span>
                      <input
                        id="backImage-replace"
                        type="file"
                        accept="image/*"
                        onChange={handleReplaceBackImage}
                        style={{ display: "none" }}
                      />
                    </label>
                    <button
                      className="image-button"
                      onClick={handleRemoveBackImage}
                    >
                      <img src={remove} alt="" />
                      <span>Remove Image</span>
                    </button>
                  </div>
                )}
              </div>
            )}
            {!formData.closeup_image1 ? (
              <label htmlFor="closeupImage1Input" className="upload-button">
                <input
                  type="file"
                  id="closeupImage1Input"
                  accept=".jpg,.jpeg,.png,.webp"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  multiple
                  name="closeup_image1"
                />
                <div className="addproduct-square-box">
                  <div className="add-images">
                    <img src={image} alt="" className="iamge-size" />
                    <span className="box-text">Closeup 1st Image</span>
                  </div>
                </div>
              </label>
            ) : (
              <div className="select-square-box-wrapper">
                <div
                  className="selected-square-box"
                  onMouseEnter={() => handleMouseEnter("closeup_image1")}
                  onMouseLeave={() => handleMouseLeave("closeup_image1")}
                >
                  <div className="selected-images">
                    <img
                      src={URL.createObjectURL(formData.closeup_image1)}
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="image-card-footer">
                    <img src={smallImage} alt="" width={24} height={24} />
                    <span className="image-footer-text">Closeup 1st Image</span>
                  </div>
                </div>

                {hoverStates.closeup_image1 && (
                  <div
                    className="addproduct-hover-content"
                    onMouseEnter={() => handleMouseEnter("closeup_image1")}
                    onMouseLeave={() => handleMouseLeave("closeup_image1")}
                  >
                    <label
                      htmlFor="closeuoImage1-replace"
                      className="image-button"
                    >
                      <img src={change} alt="" />
                      <span>Change Image</span>
                      <input
                        id="closeuoImage1-replace"
                        type="file"
                        accept="image/*"
                        onChange={handleReplaceCloseImage1}
                        style={{ display: "none" }}
                      />
                    </label>
                    <button
                      className="image-button"
                      onClick={handleRemoveCloseupImage1}
                    >
                      <img src={remove} alt="" />
                      <span>Remove Image</span>
                    </button>
                  </div>
                )}
              </div>
            )}
            {!formData.closeup_image2 ? (
              <label htmlFor="closeupImage2Input" className="upload-button">
                <input
                  type="file"
                  id="closeupImage2Input"
                  accept=".jpg,.jpeg,.png,.webp"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  multiple
                  name="closeup_image2"
                />
                <div className="addproduct-square-box">
                  <div className="add-images">
                    <img src={image} alt="" className="iamge-size" />
                    <span className="box-text">Closeup 2nd Image</span>
                  </div>
                </div>
              </label>
            ) : (
              <div className="select-square-box-wrapper">
                <div
                  className="selected-square-box"
                  onMouseEnter={() => handleMouseEnter("closeup_image2")}
                  onMouseLeave={() => handleMouseLeave("closeup_image2")}
                >
                  <div className="selected-images">
                    <img
                      src={URL.createObjectURL(formData.closeup_image2)}
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="image-card-footer">
                    <img src={smallImage} alt="" width={24} height={24} />
                    <span className="image-footer-text">Closeup 2nd Image</span>
                  </div>
                </div>

                {hoverStates.closeup_image2 && (
                  <div
                    className="addproduct-hover-content"
                    onMouseEnter={() => handleMouseEnter("closeup_image2")}
                    onMouseLeave={() => handleMouseLeave("closeup_image2")}
                  >
                    <label
                      htmlFor="closeuoImage2-replace"
                      className="image-button"
                    >
                      <img src={change} alt="" />
                      <span>Change Image</span>
                      <input
                        id="closeuoImage2-replace"
                        type="file"
                        accept="image/*"
                        onChange={handleReplaceCloseImage2}
                        style={{ display: "none" }}
                      />
                    </label>
                    <button
                      className="image-button"
                      onClick={handleRemoveCloseupImage2}
                    >
                      <img src={remove} alt="" />
                      <span>Remove Image</span>
                    </button>
                  </div>
                )}
              </div>
            )}
            {!formData.closeup_image3 ? (
              <label htmlFor="closeupImage3Input" className="upload-button">
                <input
                  type="file"
                  id="closeupImage3Input"
                  accept=".jpg,.jpeg,.png,.webp"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  multiple
                  name="closeup_image3"
                />
                <div className="addproduct-square-box">
                  <div className="add-images">
                    <img src={image} alt="" className="iamge-size" />
                    <span className="box-text">Closeup 3rd Image</span>
                  </div>
                </div>
              </label>
            ) : (
              <div className="select-square-box-wrapper">
                <div
                  className="selected-square-box"
                  onMouseEnter={() => handleMouseEnter("closeup_image3")}
                  onMouseLeave={() => handleMouseLeave("closeup_image3")}
                >
                  <div className="selected-images">
                    <img
                      src={URL.createObjectURL(formData.closeup_image3)}
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="image-card-footer">
                    <img src={smallImage} alt="" width={24} height={24} />
                    <span className="image-footer-text">Closeup 3rd Image</span>
                  </div>
                </div>

                {hoverStates.closeup_image3 && (
                  <div
                    className="addproduct-hover-content"
                    onMouseEnter={() => handleMouseEnter("closeup_image3")}
                    onMouseLeave={() => handleMouseLeave("closeup_image3")}
                  >
                    <label
                      htmlFor="closeuoImage3-replace"
                      className="image-button"
                    >
                      <img src={change} alt="" />
                      <span>Change Image</span>
                      <input
                        id="closeuoImage3-replace"
                        type="file"
                        accept="image/*"
                        onChange={handleReplaceCloseImage3}
                        style={{ display: "none" }}
                      />
                    </label>
                    <button
                      className="image-button"
                      onClick={handleRemoveCloseupImage3}
                    >
                      <img src={remove} alt="" />
                      <span>Remove Image</span>
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
          <div class="button-container">
            <button className="addproduct-button" onClick={handleCancel}>
              Cancel
            </button>
            <button className="addproduct-button-save" onClick={handleSubmit}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  dropdownValues: state.dropdownValues.dropDownValues,
  loading: state.dropdownValues.loading,
  productLoading: state.addProduct.loading,
});

const mapDispatchToProps = {
  addProduct,
  getProductsDropDownValues,
  addNewSeason,
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);
