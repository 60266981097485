import React, { useEffect, useState, useCallback } from "react";
import filter from "../../images/icons/filter.svg";
import folder from "../../images/icons/folder.svg";
import { connect } from "react-redux";
import { getTrendBrands } from "../../redux/action/addProduct";
import CircularProgress from "@mui/material/CircularProgress";
import { Link, useNavigate } from "react-router-dom";
import Brand from "../brand/Brand";
import TrendInfo from "../trendInfo/TrendInfo";
import search from "../../images/icons/search.svg";
import { getUserDetailsFromLocalStorage } from "../LocalStorage";
import space from "../../images/empty-page-new.gif";

import "./ViewTrends.css";

const ViewTrends = ({ getTrendBrands, loading, brands }) => {
  const [currentTrend, setCurrentTrend] = useState("Pearl");
  const [trendData, setTrendData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredBrands, setFilteredBrands] = useState([]);
  const [role, setRole] = useState("");
  const [showLoading, setShowLoading] = useState(true); // New state to control loading spinner

  const navigate = useNavigate();

  useEffect(() => {
    const storedTrend = localStorage.getItem("trend");
    localStorage.setItem("check", false);

    if (storedTrend) {
      setCurrentTrend(storedTrend);
    } else {
      setCurrentTrend("Pearl");
      localStorage.setItem("trend", "Pearl");
    }
  }, []);
  console.log("filteredBrands", filteredBrands);
  useEffect(() => {
    const userDetails = getUserDetailsFromLocalStorage();
    setRole(userDetails.user.role);
  }, []);

  useEffect(() => {
    const updateTrendData = () => {
      if (brands?.data) {
        if (currentTrend === "Pearl") {
          setTrendData(brands.data.pearl);
        } else if (currentTrend === "Buyer") {
          setTrendData(brands.data.buyer);
        }
      }
    };
    updateTrendData();
  }, [brands?.data, currentTrend]);

  useEffect(() => {
    const userDetails = getUserDetailsFromLocalStorage();
    if (userDetails?.user) {
      getTrendBrands(userDetails?.user?._id, userDetails?.user?.role);
    }
    setTimeout(() => {
      setShowLoading(false);
    }, 500);
  }, [getTrendBrands, currentTrend]);

  useEffect(() => {
    const filterBrands = () => {
      if (searchTerm === "") {
        setFilteredBrands(trendData);
      } else {
        const filtered = trendData.filter((product) =>
          product.brand.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredBrands(filtered);
      }
    };
    filterBrands();
  }, [searchTerm, trendData]);

  function formatDate(isoDate) {
    const dateObj = new Date(isoDate);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return dateObj.toLocaleDateString("en-US", options);
  }

  const GradientCircularProgress = () => (
    <div className="loading-container">
      <React.Fragment>
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#26408A" />
              <stop offset="50%" stopColor="#196D92" />
              <stop offset="100%" stopColor="#49883E" />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress
          sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
        />
      </React.Fragment>
    </div>
  );
  const handleSearchClick = useCallback(() => {
    if (Array.isArray(trendData) && brands.length > 0) {
      const filter = brands.filter((product) => {
        return product.brand.toLowerCase().includes(searchTerm.toLowerCase());
      });

      return setFilteredBrands(filter);
    } else {
      console.log("No products found.");
      return [];
    }
  });
  if (loading || showLoading) {
    return <GradientCircularProgress />;
  }
  const navigateToProductDetails = (brand) => {
    navigate(`/bransDetails/${currentTrend}/${brand}`);
  };
  const handleTrendChange = (trend) => {
    setCurrentTrend(trend);
    localStorage.setItem("trend", trend);
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    // Perform search logic here
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };
  return (
    <div>
      <div className="view-trends">
        <div className="trends-buttons">
          <div className="trends-button-align">
            <div>
              <button
                className={`slected-trends-button ${
                  currentTrend === "Pearl" ? "active" : ""
                }`}
                onClick={() => handleTrendChange("Pearl")}
              >
                <span className="button-text">Pearl Trends</span>
              </button>
            </div>
            <div>
              <button
                className={`slected-trends-button ${
                  currentTrend === "Buyer" ? "active" : ""
                }`}
                onClick={() => handleTrendChange("Buyer")}
              >
                <span className="button-text">Buyer Trends</span>
              </button>
            </div>
          </div>
        </div>

        <div className="view-trends-container">
          {trendData.length > 0 ? (
            <div>
              <div className="trends-header">
                <div className="brand-header-text">
                  <div>
                    <span className="header-brand">Brands</span>
                  </div>
                  <div className="count-circle">
                    <span className="brand-count">{trendData.length}</span>
                  </div>
                </div>
                <div className="trends-header-buttons">
                  <div className="brand-search-bar">
                    <input
                      type="text"
                      placeholder="Search Brand"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      onKeyPress={handleKeyPress}
                    />
                    <img
                      src={search}
                      alt=""
                      className="brand-search-icon"
                      onClick={handleSearchClick}
                    />
                  </div>
                  {(role === "Admin" ||
                    role === "SuperAdmin" ||
                    role === "Operator") && (
                    <div>
                      <Link to="/addtrend" className="add-trend-button">
                        <span className="header-button-text">
                          + Add {currentTrend} Trends
                        </span>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              {filteredBrands.length > 0 ? (
                <Brand
                  loading={loading}
                  brands={brands}
                  trendData={filteredBrands}
                  navigateToProductDetails={navigateToProductDetails}
                  formatDate={formatDate}
                  GradientCircularProgress={GradientCircularProgress}
                  folder={folder}
                />
              ) : (
                <div className="no-brands-container1">
                  <div className="brand-inner-content1">
                    <div>
                      <img src={space} alt="" className="no-brand-img2" />
                    </div>
                    <div>
                      <span className="no-product-text-big">
                        Seems Like No Filtered Brands Here!!
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="no-brands-container">
              <div className="brand-inner-content">
                <div>
                  <img src={space} alt="" className="no-brand-img1" />
                </div>
                <div>
                  <span className="no-product-text-big">
                    Seems Like No Brands Here!!
                  </span>
                </div>
                <div>
                  <span className="no-product-text-small">
                    Let's Start Filling This Space With Your Brands.
                  </span>
                </div>
                {(role === "Admin" ||
                  role === "SuperAdmin" ||
                  role === "Operator") && (
                  <div>
                    <Link to="/addtrend" className="add-trend-button">
                      <span className="header-button-text">
                        + Add {currentTrend} Trends
                      </span>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  brands: state.trendsBrand.brands,
  loading: state.trendsBrand.loading,
});

const mapDispatchToProps = {
  getTrendBrands,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewTrends);
