import React, { useState, useEffect, useCallback, useRef } from "react";
import { connect } from "react-redux";
import {
  findProducts,
  getAllProducts,
  getProductsDropDownValues,
  setVisibleCount,
} from "../../redux/action/addProduct";
import "./Dashboard.css"; // Import CSS file for styling
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import filter from "../../images/icons/filter.svg";
import filterGif from "../../images/icons/filter.gif";
import close from "../../images/icons/close.svg";
import DropdownWithCheckbox from "./DropdownWithCheckbox";
import search from "../../images/icons/search.svg";
import BestSeller from "../../images/icons/bestSeller.svg";
import space from "../../images/space.gif";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { getUserDetailsFromLocalStorage } from "../LocalStorage";
import {
  setSelectedFilteredProducts,
  updateBuildDemoStatus,
} from "../../redux/action/others";
import Popup from "reactjs-popup";
import { Checkbox, FormControlLabel } from "@mui/material";
import PptGenerator from "../../PPTGenerator/PptGenerator";
import { addProductsToIndexedDB } from "../IndexDB";
import { Close } from "@mui/icons-material";
import { MdClose } from "react-icons/md";
import stepCompleted from "../../images/step-conpleted.png";
import nextStep from "../../images/next-step.png";
import stepOngoing from "../../images/step-ongoing.png";
import checkBoxIcon from "../../images/check-box.png";
import checkBoxCheckedIcon from "../../images/check-box-checked.png";
import { Toaster, toast } from "react-hot-toast";
// import "../../font.css";

const drawerWidth = 220;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const attributeDisplayNames = {
  description: "Description",
  season: "Season",
  product_style: "Style Name / Reference. No",
  gender: "Gender",
  categories: "Categories",
  content: "Content",
  construction: "Construction",
  shrinkage: "Shrinkage",
  weight: "Weight",
  cw: "Cuttable Width",
  best_seller: "Best Seller",
  phase: "Phase",
  shipment_date: "Shipment Date",
  order_quantity: "Order Quantity (Pcs)",
  garment_fob: "Garment FOB (USD)",
  fabric_supplier: "Fabric Mill / Supplier",
  fabric_ref: "Fabric Reference. No",
  fob: "Fabric FOB (USD)",
  fabric_price: "Fabric Price ($)",
  "division":"Division",
  "yardage":"Yardage",
  "customer":"Customer",
};
const imageAttributeDisplayNames = {
  front_image_base64: "Front Image",
  back_image_base64: "Back Image",
  closeup_images_base64_1: "Detail Image 1",
  closeup_images_base64_2: "Detail Image 2",
  closeup_images_base64_3: "Detail Image 3",
};
const Dashboard = ({
  getAllProducts,
  loading,
  getProductsDropDownValues,
  dropdownValues,
  selectedGender,
  setSelectedGender,
  findProducts,
  selectedProductsData,
  buildDemoStatus,
  updateBuildDemoStatus,
  setSelectedFilteredProducts,
  selectedFilteredProductsData,
  setVisibleCount,
  visibleCount,
  setActiveItem,
  setDemoSelected,
  demoSelected,
  demoLoading,
}) => {
  // const [selectedIds, setSelectedIds] = useState([]);
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  console.log("buildDemoStatus", buildDemoStatus);
  const [searchTerm, setSearchTerm] = useState("");
  const [checkBoxClicked,setCheckBoxClicked]=useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState([]);
  const [filteredProducts, setFilterProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [role, setRole] = useState("");
  const navigate = useNavigate();
  const productRefs = useRef({});
  const location = useLocation();
  const [showLoading, setShowLoading] = useState(true); // New state to control loading spinner

  const { gender } = location.state || {}; // Destructure gender safely
  const [filterIsHovered, setfilterIsHovered] = useState(false);
  const scrollContainerRef = useRef(null);
  const [openDownloadOptions, setOpenDownloadOptions] = useState(false);
  const [openDownloadOptions1, setOpenDownloadOptions1] = useState(false);
  const [filteredProductsData, setFilteredProductsData] = useState([]);
  const closeModal = () => setOpenDownloadOptions(false);
  const closeModal1 = () => setOpenDownloadOptions1(false);
  const [checkedAttributes, setCheckedAttributes] = useState({
    _id: true,
    description: true,
    season: true,
    product_style: true,
    gender: true,
    categories: true,
    content: true,
    construction: true,
    shrinkage: true,
    weight: true,
    cw: true,
    best_seller: true,
    front_image_base64: true,
    back_image_base64: true,
  });
  console.log("selectedGender", selectedGender);
  console.log("role", role); // Fixed typo: changed selectedGender to gender

  useEffect(() => {
    sessionStorage.setItem("previousUrl", window.location.href);
  }, [location]);
  useEffect(() => {
    if (gender) {
      if (gender === "Kids") {
        setSelectedGender(["Boys", "Girls"]);
      } else if (gender === "All") {
        setSelectedGender([]);
      } else {
        setSelectedGender([gender]);
      }
    }
  }, [gender, setSelectedGender]);

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleClear = () => {
    setSearchTerm("");
    setSelectedCategories([]);
    setSelectedSeason([]);
    setSelectedGender([]);
  };

  useEffect(() => {
    const fetchData = async () => {
      const userDetails = getUserDetailsFromLocalStorage();
      if (userDetails?.user) {
        const products = await getAllProducts(
          userDetails?.user?._id,
          userDetails?.user?.role
        );
        setAllProducts(products);
        getProductsDropDownValues();
        setRole(userDetails.user.role);

        // Show loading spinner for an additional half second
        setTimeout(() => {
          setShowLoading(false);
        }, 500);
      }
    };

    fetchData();
  }, [getAllProducts, getProductsDropDownValues]);

  // const handleCheckboxChange = (productId) => {
  //   setSelectedIds((prevIds) => {
  //     if (prevIds.includes(productId)) {
  //       return prevIds.filter((id) => id !== productId); // Deselect if already selected
  //     } else {
  //       return [...prevIds, productId]; // Select if not already selected
  //     }
  //   });
  // };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (
      allProducts &&
      Array.isArray(allProducts?.data) &&
      allProducts?.data?.length > 0
    ) {
      handleSearchClick();
    } else {
      setFilterProducts([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allProducts, searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchClick = useCallback(() => {
    if (
      allProducts &&
      Array.isArray(allProducts?.data) &&
      allProducts?.data?.length > 0
    ) {
      const filter = allProducts.data.filter((product) => {
        const content = product.content.toLowerCase();
        const description = product.description.toLowerCase();
        const searchTerms = searchTerm.toLowerCase().split(" ");

        // Check if all search terms are found in content or description
        return searchTerms.every(
          (term) => content.includes(term) || description.includes(term)
        );
      });

      setFilterProducts(filter);
    } else {
      console.log("No products found.");
      setFilterProducts([]);
    }
  }, [allProducts, searchTerm]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };

  useEffect(() => {
    // Get the last viewed product ID from localStorage
    const lastViewedProductId = localStorage.getItem("lastViewedProduct");

    if (lastViewedProductId && productRefs.current[lastViewedProductId]) {
      // Scroll to the product with the matching ID
      productRefs.current[lastViewedProductId].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      // Use a timeout to delay the setting of localStorage
      setTimeout(() => {
        localStorage.setItem("lastViewedProduct", null);
      }, 1000); // Adjust timeout duration as needed
    }
  }, [filteredProducts]);

  const GradientCircularProgress = () => (
    <div className="loading-container">
      <React.Fragment>
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#26408A" />{" "}
              {/* Change color to #26408A */}
              <stop offset="50%" stopColor="#196D92" />{" "}
              {/* Change color to #196D92 */}
              <stop offset="100%" stopColor="#49883E" />{" "}
              {/* Change color to #49883E */}
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress
          sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
        />
      </React.Fragment>
    </div>
  );

  if (showLoading  || loading) {
    return <GradientCircularProgress />;
  }

  console.log("filteredProducts", filteredProducts);

  const products = filteredProducts.filter((product) => {
    const genderFilter =
      selectedGender.length === 0 || selectedGender.includes(product.gender);

    const seasonFilter =
      selectedSeason.length === 0 || selectedSeason.includes(product.season);

    const categoryFilter =
      selectedCategories.length === 0 ||
      selectedCategories.includes(product.categories);

    return genderFilter && seasonFilter && categoryFilter;
  });
  console.log("allProducts", allProducts);
  const navigateToProductDetails = (productId) => {
    localStorage.setItem("lastViewedProduct", productId);

    if (!buildDemoStatus) {
      navigate(`/productdetails/${productId}`);
    }
  };
  // selectedGender = { selectedGender };
  const addProductstoBuild = (productId) => {
    setSelectedProducts((prevSelectedProducts) => {
      if (prevSelectedProducts.includes(productId)) {
        // Remove productId from array if it already exists
        return prevSelectedProducts.filter((id) => id !== productId);
      } else {
        // Add productId to array if it doesn't exist
        return [...prevSelectedProducts, productId];
      }
    });
  };
  console.log("selectedProductId", selectedProducts);
  console.log("popup-open", openDownloadOptions);
  const getDistinctFields = (data) => {
    const fieldsSet = new Set();

    data.forEach((item) => {
      Object.keys(item).forEach((key) => {
        fieldsSet.add(key);
      });
    });

    return Array.from(fieldsSet);
  };
  const distinctProductsFields = selectedProductsData
    ? getDistinctFields(selectedProductsData)
    : [];
  console.log(distinctProductsFields);
  console.log(selectedProductsData);
  console.log("selectedProducts", selectedProducts);
  console.log("checkedAttributes", checkedAttributes);
  const loadMore = () => {
    setVisibleCount(visibleCount + 20);
  };
  function getDistinctImageFields(data) {
    let maxCloseupImages = 0;

    // Determine the maximum length of closeup_images array
    data.forEach((product) => {
      if (
        product.closeup_images_base64 &&
        product.closeup_images_base64.length > maxCloseupImages
      ) {
        maxCloseupImages = product.closeup_images_base64.length;
      }
    });

    // Create the fields array with mandatory fields
    const fields = ["front_image_base64", "back_image_base64"];

    // Add closeup_image fields with indexes
    for (let i = 0; i < maxCloseupImages; i++) {
      fields.push(`closeup_images_base64_${i + 1}`);
    }

    return fields;
  }
  const distinctProductImageFields = selectedProductsData
    ? getDistinctImageFields(selectedProductsData)
    : [];
  console.log(distinctProductImageFields);
  // selectedGender = { selectedGender };
  const buildDemoConfirmClick = () => {
    findProducts(selectedProducts);
    setOpenDownloadOptions((o) => !o);
  };
  const buildDemoCancelClick = () => {
    localStorage.setItem("activeItem", "");
    setActiveItem("");
    updateBuildDemoStatus(false);
    setOpenDownloadOptions(false);
    setSelectedProducts([]);
    setDemoSelected(false);
  };
  const buildDemoPopupCloseClick=()=>{
    localStorage.setItem("activeItem", "");
    setActiveItem("");
    updateBuildDemoStatus(false);
    setOpenDownloadOptions(false);
    setSelectedProducts([]);
    setDemoSelected(false);
    setOpenDownloadOptions(false);
    setOpenDownloadOptions1(false);
  }
  const handleCheckboxChange = (attribute) => {
    setCheckBoxClicked(false);
    setCheckedAttributes((prevState) => {
      const newState = { ...prevState };
      let selectionHistory = [...(prevState.selectionHistory || [])];
      
      // Check if the attribute is a special field
      const isSpecialField = attribute.startsWith("closeup_images_base64") || 
                              attribute === "front_image_base64" || 
                              attribute === "back_image_base64";
  
      // Check if any closeup images are selected
      const closeupImageKeys = Object.keys(newState).filter(key => key.startsWith("closeup_images_base64"));
      const closeupImageSelectedCount = closeupImageKeys.filter(key => newState[key]).length;
  
      const anyCloseupImageSelected = closeupImageSelectedCount > 0;
  
      // Handle special fields
      if (isSpecialField) {
        if (newState[attribute]) {
          // Unselecting special field
          newState[attribute] = false;
          // Remove from history if it exists
          selectionHistory = selectionHistory.filter((item) => item !== attribute);
        } else {
          // Selecting special field
          newState[attribute] = true;
          // Add to history if not already present
          if (!selectionHistory.includes(attribute)) {
            selectionHistory.push(attribute);
          }
  
          // Handle the limit for closeup images
          if (attribute.startsWith("closeup_images_base64")) {
            if (closeupImageSelectedCount >= 2) {
              // Find and unselect the earliest selected closeup image
              const earliestSelectedCloseup = selectionHistory
                .filter(item => item.startsWith("closeup_images_base64"))
                .shift(); // Get the earliest selected item (first in the history)
              if (earliestSelectedCloseup) {
                newState[earliestSelectedCloseup] = false;
                selectionHistory = selectionHistory.filter((item) => item !== earliestSelectedCloseup);
              }
            }
          }
        }
      } else {
        // Handle non-special fields with 12-field limit if any closeup image is selected
        if (newState[attribute]) {
          // Unselecting non-special field
          newState[attribute] = false;
          // Remove from history
          selectionHistory = selectionHistory.filter((item) => item !== attribute);
        } else {
          // Selecting non-special field
          if (anyCloseupImageSelected) {
            const nonSpecialSelectedCount = Object.keys(newState)
              .filter(key => !key.startsWith("closeup_images_base64") &&
                             key !== "front_image_base64" &&
                             key !== "back_image_base64" &&
                             newState[key] === true)
              .length;
  
            if (nonSpecialSelectedCount >= 11) {
              // Find and unselect the most recently selected non-special attribute
              const mostRecentlySelected = selectionHistory
                .filter(item => !item.startsWith("closeup_images_base64") &&
                                item !== "front_image_base64" &&
                                item !== "back_image_base64")
                .pop(); // Get the most recently selected item (last in the history)
              if (mostRecentlySelected) {
                newState[mostRecentlySelected] = false;
                selectionHistory = selectionHistory.filter((item) => item !== mostRecentlySelected);
              }
            }
          }
  
          newState[attribute] = true;
          // Add to history if not already present
          if (!selectionHistory.includes(attribute)) {
            selectionHistory.push(attribute);
          }
        }
      }
  
      console.log("Checked attributes:", newState);
      console.log("Selection history:", selectionHistory);
  
      // Save the updated history in the state
      return { ...newState, selectionHistory };
    });
  };
  const handleSelectAll = () => {
    const newCheckedAttributes = {};
    filteredProductAttributes.forEach((attribute) => {
      newCheckedAttributes[attribute] = true;
    });
    setCheckedAttributes((prevCheckedAttributes) => {
      const newCheckedAttributes = { ...prevCheckedAttributes };
      filteredProductAttributes.forEach((attribute) => {
        newCheckedAttributes[attribute] = true;
      });
      return newCheckedAttributes;
    });
    setCheckBoxClicked(true);
  };
  console.log("distinctProductImageFields", distinctProductImageFields);
  console.log("checkedAttributes", checkedAttributes);
  const handleSelectAllImages = () => {
    setCheckedAttributes((prevCheckedAttributes) => {
      const newCheckedAttributes = { ...prevCheckedAttributes };
      distinctProductImageFields.forEach((attribute) => {
        newCheckedAttributes[attribute] = true;
      });
      return newCheckedAttributes;
    });
  };
  const buildDemoPopup1ConfirmClick = () => {
    setOpenDownloadOptions1(true);
    setOpenDownloadOptions(false);
    // if(distinctProductImageFields?.length > 2){
    // }
  };
  const buildDemoPopup2BackClick = () => {
    setOpenDownloadOptions(true);
    setOpenDownloadOptions1(false);
    setCheckedAttributes({
      _id: true,
      description: true,
      season: true,
      product_style: true,
      gender: true,
      categories: true,
      content: true,
      construction: true,
      shrinkage: true,
      weight: true,
      cw: true,
      best_seller: true,
      phase: true,
      front_image_base64: true,
      back_image_base64: true,
    });
  };
  const buildDemoPopup2ConfirmClick = async () => {
    const filterData = (data, filter) => {
      // Extract numbers from closeup_images_base64 keys and store them in an array
      const closeupImageIndexes = Object.keys(filter)
        .filter(
          (key) => key.startsWith("closeup_images_base64_") && filter[key]
        )
        .map((key) => parseInt(key.split("_")[3], 10) - 1); // Subtract 1 to match array index

      return data.map((item) => {
        const filteredItem = {};
        Object.keys(filter).forEach((key) => {
          if (item.hasOwnProperty(key) && filter[key]) {
            filteredItem[key] = item[key];
          }
        });

        // Handle closeup_images_base64 array based on selected indexes
        if (closeupImageIndexes.length > 0 && item.closeup_images_base64) {
          filteredItem.closeup_images_base64 = closeupImageIndexes
            .map((index) => item.closeup_images_base64[index])
            .filter((image) => image !== undefined); // Filter out undefined images
        }

        return filteredItem;
      });
    };

    // Filter the product data based on the selected filters
    const filteredProductsDataRedux = filterData(
      selectedProductsData,
      checkedAttributes
    );
    console.log("filteredProductsDataRedux", filteredProductsDataRedux);
    // Update the state with filtered data
    setFilteredProductsData(filteredProductsDataRedux);
    // Save the filtered products data to IndexedDB
    try {
      await addProductsToIndexedDB(
        "productsDB",
        "products",
        1,
        filteredProductsDataRedux
      );
    } catch (error) {
      console.error("Error saving to IndexedDB:", error);
    }

    // Save the filtered products data to localStorage
    // localStorage.setItem("filteredProducts", JSON.stringify(filteredProductsDataRedux));

    // Close the download options popup and navigate to the generate-doc page
    setOpenDownloadOptions1(false);
    setTimeout(() => {
      updateBuildDemoStatus(false);
      navigate("/generate-doc");
    }, 500);
  };
  const filteredProductAttributes = distinctProductsFields.filter(
    (attribute) =>
      ![
        "_id",
        "createdAt",
        "updatedAt",
        "closeup_images",
        "front_image",
        "back_image",
        "front_image_base64",
        "back_image_base64",
        "closeup_images_base64",
        "documents",
        "createdBy",
        "currency",
        "createdby",
        "garment_currency",
        "fabric_currency",
        "__v",
      ].includes(attribute)
  );
  console.log(filteredProductAttributes)
  const specialAttributes = [
    "fabric_supplier",
    "fabric_ref",
    "fob",
    "fabric_price",
  ];
  const checkBoxImageSrc = Object.entries(checkedAttributes).some(
    ([key, value]) => !key.startsWith("closeup_images_base64") && value
  ) ? checkBoxIcon : checkBoxCheckedIcon;
  console.log(checkBoxImageSrc)
  const sortedProductAttributes = [
    ...filteredProductAttributes.filter(
      (attribute) => !specialAttributes.includes(attribute)
    ),
    ...filteredProductAttributes.filter((attribute) =>
      specialAttributes.includes(attribute)
    ),
  ];
 console.log(checkedAttributes)
  return (
    <div className="view-products">
      {buildDemoStatus && (
        <div className="build-demo-footer">
          <div className="demo-footer-button-container">
            <div className="selected-demo-products-count">
              {selectedProducts.length} Products selected
            </div>
            <button
              className="popup-cancel-button"
              onClick={buildDemoCancelClick}
            >
              Cancel
            </button>
            <button
              className="popup-confirm-button"
              onClick={buildDemoConfirmClick}
              disabled={selectedProducts.length === 0}
              style={{
                cursor: selectedProducts.length > 0 ? "pointer" : "not-allowed",
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      )}
      <Popup
        open={openDownloadOptions}
        closeOnDocumentClick={false}
        onClose={closeModal}
      >
        <div className="popup-container-in">
          <div className="attribute-popup-title">
            {" Attribute Settings"}
            <button
              className="popup-close-button"
              onClick={buildDemoPopupCloseClick}
            >
              <MdClose />
            </button>
          </div>

          {demoLoading ? (
            // Show only the spinner if demoLoading is true
            <div className="circular-progress-container">
              <CircularProgress />
            </div>
          ) : (
            // Show the rest of the content if demoLoading is false
            <div className="attribute-popup-content">
              <div className="attribute-subtitle-container">
                <div className="attribute-subtitle-container-in">
                  <div className="step1-div ongoing">
                    <img src={stepOngoing} alt="" /> <span>Image Settings</span>
                  </div>
                  <div className="hr-line-div"></div>
                  <div className="step2-div next">
                    <img src={nextStep} alt="" /> <span>Field Settings</span>
                  </div>
                </div>
              </div>
              <div className="select-all-div">
                <span>Select the images you want to show</span>
                {/* <button
            className="select-all-button"
            onClick={handleSelectAllImages}
          >
            <img
              src={checkBoxIcon}
              disabled={distinctProductImageFields.length === 0}
              alt=""
            />
            Select All
          </button> */}
              </div>
              <div className="hr-line-div-main"></div>
              <div className="attribute-image-options-list">
                {Object.entries(checkedAttributes).some(
                  ([key, value]) => key.startsWith("closeup") && value
                ) && (
                  <div className="fields-note-container">
                    Note: You can only select up to 12 fields if you choose all
                    the images.
                  </div>
                )}
                {distinctProductImageFields.map((attribute) => (
                  <div className="each-image-option" key={attribute}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={
                            attribute === "front_image_base64" ||
                            attribute === "back_image_base64"
                          }
                          checked={
                            attribute === "front_image_base64" ||
                            attribute === "back_image_base64" ||
                            checkedAttributes[attribute] ||
                            false
                          }
                          sx={{
                            color: "rgb(38, 64, 138)",
                            "&.Mui-checked": {
                              color: "rgb(38, 64, 138)",
                            },
                          }}
                          onChange={() => {
                            console.log(`Checkbox clicked: ${attribute}`);
                            handleCheckboxChange(attribute);
                          }}
                        />
                      }
                      label={imageAttributeDisplayNames[attribute]}
                    />
                  </div>
                ))}
              </div>
              <div className="attribute-button-container ">
                <div className="demo-popup-footer-button-container">
                  <button className="popup-cancel-button" onClick={closeModal}>
                    Cancel
                  </button>
                  <button
                    className="popup-confirm-button"
                    onClick={buildDemoPopup1ConfirmClick}
                  >
                    Save & Next
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </Popup>
      <Popup
        open={openDownloadOptions1}
        closeOnDocumentClick={false}
        onClose={closeModal1}
      >
        <div className="popup-container-in">
          <div className="attribute-popup-title">
            {" Attribute Settings"}
            <button
              className="popup-close-button"
              onClick={buildDemoPopupCloseClick}
            >
              <MdClose />
            </button>
          </div>
          <div className="attribute-popup-content">
            <div className="attribute-subtitle-container">
              <div className="attribute-subtitle-container-in">
                <div className="step1-div completed">
                  <img src={stepCompleted} alt="" /> <span>Image Settings</span>
                </div>
                <div className="hr-line-div"></div>
                <div className="step2-div ongoing">
                  <img src={stepOngoing} alt="" /> <span>Field Settings</span>
                </div>
              </div>
            </div>
            <div className="select-all-div">
              <span>Select the details you want to show</span>
              <button
                className="select-all-button"
                onClick={handleSelectAll}
                disabled={
                  Object.entries(checkedAttributes).some(
                    ([key, value]) =>
                      key.startsWith("closeup_images_base64") && value
                  ) || sortedProductAttributes.length === 0
                }
              >
                <img
                  src={checkBoxClicked ? checkBoxCheckedIcon : checkBoxIcon}
                  alt=""
                />
                Select All
              </button>
            </div>
            <div className="hr-line-div-main"></div>
            {Object.entries(checkedAttributes).some(
              ([key, value]) => key.startsWith("closeup") && value
            ) && (
              <div className="fields-note-container">
              Note: You can only select up to 12 fields if you choose all
              the images.
              </div>
            )}
            <div className="attribute-options-list">
              {sortedProductAttributes.map((attribute) => (
                <div key={attribute}>
                  {/* <label>
                      <input
                        type="checkbox"
                        checked={checkedAttributes[attribute] || false}
                        onChange={() => handleCheckboxChange(attribute)}
                      />
                      {attribute}
                    </label> */}
                  <FormControlLabel
                    sx={{
                      paddingLeft: "12px",
                    }}
                    label={attributeDisplayNames[attribute]}
                    control={
                      <Checkbox
                        disabled={attribute === "description"}
                        checked={
                          checkedAttributes[attribute] ||
                          false ||
                          attribute === "description"
                        }
                        onChange={() => handleCheckboxChange(attribute)}
                        sx={{
                          color: "rgb(38, 64, 138)",
                          "&.Mui-checked": {
                            color: "rgb(38, 64, 138)",
                          },
                        }}
                      />
                    }
                  />
                </div>
              ))}
            </div>
            <div className="attribute-button-container popup-2">
              <button
                onClick={buildDemoPopup2BackClick}
                className="popup-2-back-button"
              >
                Back
              </button>
              <div className="demo-popup-footer-button-container">
                <button className="popup-cancel-button" onClick={closeModal1}>
                  Cancel
                </button>
                <button
                  className="popup-confirm-button"
                  onClick={buildDemoPopup2ConfirmClick}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </Popup>
      <div className="view-products-container">
        {(products && products?.length > 0) ||
        (allProducts && allProducts?.data && allProducts?.data?.length > 0) ? (
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
              <Toolbar>
                <div>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                      ...(open && {
                        width: "220px",
                      }),
                    }}
                  >
                    <div className="filter-button">
                      <span style={{ color: "#000" }}>Filters</span>
                      <div
                        onMouseEnter={() => setfilterIsHovered(true)}
                        onMouseLeave={() => setfilterIsHovered(false)}
                      >
                        {!filterIsHovered && (
                          <img src={filter} alt="" width={24} height={24} />
                        )}
                        {filterIsHovered && (
                          <img src={filterGif} alt="" width={24} height={24} />
                        )}
                      </div>
                    </div>
                  </IconButton>
                </div>

                <div className="filter-bar">
                  <div className="search-bar">
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={handleSearchChange}
                      onKeyPress={handleKeyPress}
                    />
                    <img
                      src={search}
                      alt=""
                      className="search-icon"
                      onClick={handleSearchClick}
                    />
                  </div>
                  {(role === "Admin" ||
                    role === "SuperAdmin" ||
                    role === "Operator") && (
                    <Link
                      to={!demoSelected ? "/addproduct" : "#"}
                      className="Addproduct-button"
                      onClick={(e) => demoSelected && e.preventDefault()}
                      style={{
                        cursor: !demoSelected ? "pointer" : "not-allowed",
                      }}
                    >
                      <span className="plus">+</span> Add Product
                    </Link>
                  )}
                </div>
              </Toolbar>
            </AppBar>

            <Drawer
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: drawerWidth,
                  boxSizing: "border-box",
                  overflow: "hidden",
                },
              }}
              variant="persistent"
              anchor="left"
              open={open}
            >
              <DrawerHeader
                style={{
                  minHeight: "44px",
                  padding: "0px 20px",
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #CDE5FF",
                }}
              >
                <span>Filter</span>
                <IconButton onClick={handleDrawerClose}>
                  <img src={close} alt="" />
                </IconButton>
              </DrawerHeader>

              {(searchTerm ||
                selectedCategories.length > 0 ||
                selectedSeason.length > 0 ||
                selectedGender.length > 0) && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "16px",
                  }}
                >
                  <button className="clear-filter" onClick={handleClear}>
                    Clear Filters
                  </button>
                </div>
              )}
              <div className="dropdown-scroll">
                <DropdownWithCheckbox
                  options={dropdownValues?.data[0]?.categories}
                  name="Categories"
                  selectedOptions={selectedCategories}
                  setSelectedOptions={setSelectedCategories}
                />
                <DropdownWithCheckbox
                  options={dropdownValues?.data[0]?.gender}
                  name="Gender"
                  selectedOptions={selectedGender}
                  setSelectedOptions={setSelectedGender}
                />
                <DropdownWithCheckbox
                  options={dropdownValues?.data[0]?.seasons}
                  name="Season"
                  selectedOptions={selectedSeason}
                  setSelectedOptions={setSelectedSeason}
                />
              </div>
            </Drawer>
            <Main open={open} className="Main-root">
              {products && products.length > 0 ? (
                <div className="product-list">
                  {products.slice(0, visibleCount).map((product) => (
                    <div
                      key={product._id}
                      ref={(el) => (productRefs.current[product._id] = el)}
                      className="product-card"
                      onClick={() => navigateToProductDetails(product._id)}
                    >
                      {buildDemoStatus && (
                        <div className="product-card-over-build-demo">
                          <FormControlLabel
                            sx={{
                              paddingLeft: "12px",
                            }}
                            control={
                              <Checkbox
                                // checked={selectedProducts.includes(product._id)}
                                onChange={() => addProductstoBuild(product._id)}
                                sx={{
                                  color: "rgb(38, 64, 138)",
                                  "&.Mui-checked": {
                                    color: "rgb(38, 64, 138)",
                                  },
                                }}
                              />
                            }
                          />
                        </div>
                      )}
                      {product.best_seller && (
                        <div className="icon-container">
                          <img
                            src={BestSeller}
                            alt="Best Seller"
                            width={44}
                            height={57}
                          />
                        </div>
                      )}
                      <div className="image-container">
                        <img
                          src={product.front_image}
                          alt={product.description}
                          loading="lazy"
                        />
                      </div>
                      <div className="product-footer">
                        <span className="footer-big-text">
                          {product.description}
                        </span>
                        <span className="footer-small-text">
                          {product.content}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              ) : !loading &&
                loading !== "true" &&
                loading !== "1" &&
                products &&
                products.length === 0 ? (
                <div className="no-filteredproducts-container">
                  <div className="filterd-inner-content">
                    <div>
                      <img src={space} alt="" className="no-products-img" />
                    </div>
                    <div>
                      <span className="no-product-text-big">
                        Seems Like No Products For This Filter Here!!
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <GradientCircularProgress />
              )}
              {visibleCount < products.length && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <button onClick={loadMore} className="loadmore">
                    Load {products.length - visibleCount} More Products!
                  </button>
                </div>
              )}
            </Main>
          </Box>
        ) : (
          <div className="no-products-container">
            <div className="inner-content">
              <div>
                <img src={space} alt="" className="no-products-img" />
              </div>
              <div>
                <span className="no-product-text-big">
                  Seems Like No Products Here!!
                </span>
              </div>
              <div>
                <span className="no-product-text-small">
                  Let's Start Filling This Space With Your Products.
                </span>
              </div>
              <div>
                <Link to="/addproduct" className="Addproduct-button2">
                  <span className="plus">+</span> Add Product
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.allProducts.loading,
  dropdownValues: state.dropdownValues.dropDownValues,
  selectedProductsData: state.getProducts.products,
  demoLoading: state.getProducts.loading,
  buildDemoStatus: state.getBuildDemoState.selected,
  selectedFilteredProductsData: state.selectedFilteredProducts.filteredProducts,
  visibleCount: state.loadMore.visibleCount,
});

const mapDispatchToProps = {
  getAllProducts,
  getProductsDropDownValues,
  findProducts,
  updateBuildDemoStatus,
  setSelectedFilteredProducts,
  setVisibleCount,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

// <DropdownWithCheckbox
//   options={options1}
//   name="Fabrics"
//   selectedOptions={selectedFabrics}
//   setSelectedOptions={setSelectedFabrics}
// />


// const handleCheckboxChange = (attribute) => {
//   setCheckedAttributes((prevState) => {
//     const newState = { ...prevState };
//     let selectionHistory = [...(prevState.selectionHistory || [])];
    
//     // Check if the attribute is a special field
//     const isSpecialField = attribute.startsWith("closeup_images_base64") || 
//                             attribute === "front_image_base64" || 
//                             attribute === "back_image_base64";

//     // Check if any closeup images are selected
//     const anyCloseupImageSelected = Object.keys(newState).some(key =>
//       key.startsWith("closeup_images_base64") && newState[key]
//     );

//     if (isSpecialField) {
//       // Handle special fields
//       if (newState[attribute]) {
//         // Unselecting special field
//         newState[attribute] = false;
//         // Remove from history if it exists
//         selectionHistory = selectionHistory.filter((item) => item !== attribute);
//       } else {
//         // Selecting special field
//         newState[attribute] = true;
//         // Add to history if not already present
//         if (!selectionHistory.includes(attribute)) {
//           selectionHistory.push(attribute);
//         }
//       }
//     } else {
//       // Handle non-special fields with 12-field limit if any closeup image is selected
//       if (newState[attribute]) {
//         // Unselecting non-special field
//         newState[attribute] = false;
//         // Remove from history
//         selectionHistory = selectionHistory.filter((item) => item !== attribute);
//       } else {
//         // Selecting non-special field
//         if (anyCloseupImageSelected) {
//           const nonSpecialSelectedCount = Object.keys(newState)
//             .filter(key => !key.startsWith("closeup_images_base64") &&
//                            key !== "front_image_base64" &&
//                            key !== "back_image_base64" &&
//                            newState[key] === true)
//             .length;

//           if (nonSpecialSelectedCount >= 11) {
//             // Find and unselect the most recently selected non-special attribute
//             const mostRecentlySelected = selectionHistory
//               .filter(item => !item.startsWith("closeup_images_base64") &&
//                               item !== "front_image_base64" &&
//                               item !== "back_image_base64")
//               .pop(); // Get the most recently selected item (last in the history)
//             if (mostRecentlySelected) {
//               newState[mostRecentlySelected] = false;
//               selectionHistory = selectionHistory.filter((item) => item !== mostRecentlySelected);
//             }
//           }
//         }

//         newState[attribute] = true;
//         // Add to history if not already present
//         if (!selectionHistory.includes(attribute)) {
//           selectionHistory.push(attribute);
//         }
//       }
//     }

//     console.log("Checked attributes:", newState);
//     console.log("Selection history:", selectionHistory);

//     // Save the updated history in the state
//     return { ...newState, selectionHistory };
//   });
// };