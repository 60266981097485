import React from "react";
import "./Brand.css";
const Brand = ({
  brands,
  trendData,
  navigateToProductDetails,
  formatDate,
  GradientCircularProgress,
  folder,
  loading,
}) => {
  return (
    <>
      {loading || !brands || !brands.data ? (
        <div className="brand-loading-container">
          <GradientCircularProgress />
        </div>
      ) : (
        <div className="folder-list">
          {trendData.map((trend) => (
            <div
              className="folder-card"
              key={trend._id}
              onClick={() => navigateToProductDetails(trend.brand)}
            >
              <div className="folder-image-container">
                <img src={folder} alt="" width={80} height={80} />
              </div>
              <div className="folder-footer">
                <span className="folder-footer-big-text">{trend.brand}</span>
                <span className="folder-footer-small-text">
                  Last Updated: {formatDate(trend.latestModified)}
                </span>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Brand;
