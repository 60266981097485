import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { FaPlus, FaSignOutAlt } from "react-icons/fa";
import logo from "../../images/Logo.svg";
import menu from "./icons/menu.svg";
import menuGif from "./icons/menu.gif";
import presentation from "./icons/presentation.svg";
import profile from "./icons/profile.png";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Toaster, toast } from "react-hot-toast";
import { HiOutlineLogout } from "react-icons/hi";
import { connect } from "react-redux";
import { updateBuildDemoStatus } from "../../redux/action/others";
import { Prev } from "react-bootstrap/esm/PageItem";
import { getUserDetailsFromLocalStorage } from "../LocalStorage";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";

const Navbar = ({
  selectedGender,
  onLoginSuccess,
  activeItem,
  setActiveItem,
  gender,
  setGender,
  updateBuildDemoStatus,
  buildDemoStatus,
  demoSelected,
  setDemoSelected,
  allProducts,
}) => {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState("");

  const [menuIsHovered, setMenuIsHovered] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  useEffect(() => {
    const userDetails = getUserDetailsFromLocalStorage();
    const activeButton = localStorage.getItem("activeItem");

    setActiveItem(activeButton);
    setUserDetails(userDetails.user);
  }, [setActiveItem]);
  useEffect(() => {
    const handleStorageChange = () => {
      const storedGender = localStorage.getItem("gender");
      if (storedGender) {
        setGender(storedGender);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [setGender]);
  useEffect(() => {
    if (demoSelected) {
      localStorage.setItem("activeItem", "Demo");
      setActiveItem("Demo");
    }
  }, [demoSelected, setActiveItem]);
  useEffect(() => {
    if (selectedGender && selectedGender.length) {
      console.log("selectedGender", selectedGender);
      let genderToStore;
      if (
        ["Women", "Men", "Unisex", "Boys", "Girls"].every((gender) =>
          selectedGender.includes(gender)
        )
      ) {
        genderToStore = "All";
        console.log("gender", genderToStore);
      } else if (
        selectedGender.every((gender) => ["Boys", "Girls"].includes(gender)) &&
        (selectedGender.includes("Boys") || selectedGender.includes("Girls"))
      ) {
        genderToStore = "Kids";
      } else {
        genderToStore = selectedGender.length === 1 ? selectedGender[0] : "";
      }
      localStorage.setItem("gender", genderToStore);
      setGender(genderToStore);
    } else if (selectedGender.length === 0) {
      localStorage.setItem("gender", "All");
      const genderToStore = "All";
      setGender(genderToStore);
    } else {
      localStorage.setItem("gender", "");
      setGender("");
    }
  }, [selectedGender, setGender]);

  console.log("gendernow", gender);

  const handleClose = (selectedGender) => {
    if (selectedGender) {
      localStorage.setItem("gender", selectedGender);
      setGender(selectedGender);
      localStorage.setItem("activeItem", "");
      setActiveItem("");

      navigate("/dashboard", { state: { gender: selectedGender } });
    }
    setAnchorEl(null);
  };
  console.log("demoSelected", demoSelected);
  const handleLogout = () => {
    try {
      localStorage.removeItem("userDetails");
      toast.success(
        "You have been logged out. Redirecting to the Login page..."
      );

      setTimeout(() => {
        navigate("/");
        onLoginSuccess(false);
      }, 1000);
    } catch (error) {
      console.error("Logout failed:", error);
      toast.error("An error occurred during logout. Please try again.");
    }
  };

  const handleViewTrends = () => {
    localStorage.setItem("activeItem", "viewTrends");
    setGender(localStorage.setItem("gender", ""));
    navigate("/viewtrends");
    setActiveItem("viewTrends");
  };
  const handleDemo = () => {
    setDemoSelected(true);
    updateBuildDemoStatus(true);
  };
  // const handle3D = () => {
  //   localStorage.setItem("activeItem", "3D");
  //   navigate("/viewtrends");
  //   setActiveItem("3D");
  // };

  const handleDashboard = () => {
    localStorage.setItem("activeItem", "");
    setGender(localStorage.setItem("gender", ""));
    navigate("/landingpage");
    setActiveItem("");
  };
  const handleProfile = () => {
    localStorage.setItem("activeItem", "Profile");
    setGender(localStorage.setItem("gender", ""));

    if (userDetails?.role === "Admin") {
      navigate("/profile");
    } else if (userDetails?.role === "SuperAdmin") {
      navigate("/super-admin-profile");
    }

    setActiveItem("Profile");
  };

  const isDashboardPage = window.location.pathname === "/dashboard";
  console.log("isDashboardPage", isDashboardPage);
  return (
    <div className="navbar-content">
      <Toaster />
      <div className="navbar-left-icons">
        <div className={activeItem === "menu" ? "active-item" : ""}>
          <button
            style={{ border: "none", backgroundColor: "transparent" }}
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <div className="menu inactive">
              <div
                onMouseEnter={() => setMenuIsHovered(true)}
                onMouseLeave={() => setMenuIsHovered(false)}
              >
                {!menuIsHovered && (
                  <img
                    src={menu}
                    className="icon"
                    id="editIcon"
                    alt=""
                    width={24}
                    height={24}
                  />
                )}
                {menuIsHovered && (
                  <img
                    src={menuGif}
                    className="icon"
                    id="editIcon"
                    alt=""
                    width={24}
                    height={24}
                  />
                )}
              </div>
              <div>
                <span className="menu-text">Menu</span>
              </div>
            </div>
          </button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => handleClose(gender)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{
              "& .MuiMenu-paper": {
                width: 200,
                height: 180,
                padding: "15px",
              },
              "& .MuiMenuItem-root": {
                fontSize: "16px",
                color: "#1A1B21",
                fontWeight: "400",
                borderBottom: "1px solid #B5C4FF",
                "&:hover": {
                  background:
                    "linear-gradient(to right, #26408a 0%, #196d92 20%, #49883e 40%, #26408A 60%)",
                  "-webkit-background-clip": "text",
                  "-webkit-text-fill-color": "transparent",
                  fontSize: "16px",
                  fontWeight: "700",
                },
              },
            }}
          >
            <MenuItem
              onClick={() => handleClose("All")}
              className={gender === "All" ? "activeMenuItem" : "menu-item"}
            >
              All Products
            </MenuItem>
            <MenuItem
              onClick={() => handleClose("Women")}
              className={gender === "Women" ? "activeMenuItem" : "menu-item"}
            >
              Women
            </MenuItem>
            <MenuItem
              onClick={() => handleClose("Men")}
              className={gender === "Men" ? "activeMenuItem" : "menu-item"}
            >
              Men
            </MenuItem>
            <MenuItem
              onClick={() => handleClose("Kids")}
              className={gender === "Kids" ? "activeMenuItem" : "menu-item"}
            >
              Kids
            </MenuItem>
          </Menu>
        </div>

        <div
          className={activeItem === "viewTrends" ? "active-item" : "inactive"}
          style={{ cursor: !demoSelected ? "pointer" : "not-allowed" }}
          onClick={!demoSelected ? handleViewTrends : undefined}
        >
          <span className="navbar-icon-text">Trends</span>
        </div>
      </div>
      <div
        onClick={!demoSelected ? handleDashboard : undefined}
        style={{ cursor: !demoSelected ? "pointer" : "not-allowed" }}
      >
        <img src={logo} alt="" width={180} height={40} />
      </div>
      <div className="navbar-right-icons">
        {(userDetails?.role === "Admin" ||
          userDetails?.role === "SuperAdmin" ||
          userDetails?.role === "Marketing") && (
          <button
            className={
              activeItem === "Demo"
                ? "presentation-button-active"
                : "presentation-button"
            }
            onClick={
              isDashboardPage && allProducts?.data?.length > 0
                ? handleDemo
                : () => {}
            }
            style={{
              cursor:
                isDashboardPage && allProducts?.data?.length > 0
                  ? "pointer"
                  : "not-allowed",
            }}
            disabled={!isDashboardPage || allProducts?.data?.length === 0}
          >
            <div>
              <img src={presentation} alt="Presentation Icon" />
            </div>
            <div>
              <span className="navbar-icon-text">Build Your Demo</span>
            </div>
          </button>
        )}
        <button
          disabled={demoSelected}
          className={
            activeItem === "Profile"
              ? "presentation-button-active"
              : "presentation-button"
          }
          style={{ cursor: !demoSelected ? "pointer" : "not-allowed" }}
          onClick={
            userDetails?.role === "Admin" || userDetails?.role === "SuperAdmin"
              ? handleProfile
              : undefined
          }
        >
          <div>
            <PermIdentityIcon />
          </div>
          <div>
            <span className="navbar-icon-text">{userDetails?.username}</span>
          </div>
        </button>

        <div className="navbar-signout-button" onClick={handleLogout}>
          <HiOutlineLogout size={32} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  buildDemoStatus: state.getBuildDemoState.selected,
  allProducts: state.allProducts.allProducts,
});

const mapDispatchToProps = {
  updateBuildDemoStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
