import { server } from "../store";
import axios from "axios";

export const addProduct = (productdetails) => async (dispatch) => {
  console.log("redux", productdetails);
  try {
    dispatch({ type: "ADD_PRODUCT_REQUEST" });

    const { data } = await axios.post(
      `${server}/product/add-product`,
      productdetails,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    console.log(data);
    dispatch({ type: "ADD_PRODUCT_SUCCESS", payload: data });
    return true;
  } catch (error) {
    dispatch({
      type: "ADD_PRODUCT_FAILED",
      payload: error.response.data.message,
    });
    return false;
  }
};

export const findProduct = (id) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_PRODUCT_REQUEST" });
    const res = await axios.get(`${server}/product/find-product?id=${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    dispatch({ type: "FETCH_PRODUCT_SUCCESS", payload: res.data });

    return res;
  } catch (error) {
    console.error("Error fetching products:", error.message);
    dispatch({
      type: "FETCH_PRODUCT_FAILED",
      payload: error,
    });
    throw error;
  }
};
export const findProducts = (ids) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_PRODUCTS_REQUEST" });

    const { data } = await axios.post(
      `${server}/product/find-products`,
      { ids: ids },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    const productData = data.data;
    dispatch({ type: "FETCH_PRODUCTS_SUCCESS", payload: productData });

    return data;
  } catch (error) {
    console.error("Error fetching products:", error.message);
    dispatch({
      type: "FETCH_PRODUCTS_FAILED",
      payload: error,
    });
    throw error;
  }
};

export const getAllProducts = (id, role) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_ALL_PRODUCT_REQUEST" });
    const data = await axios.get(
      `${server}/product/all-products?id=${id}&role=${role}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    dispatch({ type: "FETCH_ALL_PRODUCT_SUCCESS", payload: data });

    console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching products:", error.message);
    dispatch({
      type: "FETCH_All_PRODUCT_FAILED",
      payload: error,
    });
    throw error;
  }
};

export const deleteProduct = (type, id) => async (dispatch) => {
  try {
    dispatch({ type: "DELETE_PRODUCT_REQUEST" });
    const data = await axios.delete(
      `${server}/product/delete-product?type=${type}&id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    dispatch({ type: "DELETE_PRODUCT_SUCCESS", payload: data });

    console.log(data);
    return data;
  } catch (error) {
    console.error("Error deleting product:", error.message);
    dispatch({
      type: "DELETE_PRODUCT_FAILED",
      payload: error,
    });
    throw error;
  }
};

export const getProductsDropDownValues = () => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_PRODUCT_DROPDOWNVALUES_REQUEST" });
    const data = await axios.get(
      `${server}/product/get-product-dropdownvalues`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    dispatch({
      type: "FETCH_PRODUCT_DROPDOWNVALUES_SUCCESS",
      payload: data,
    });

    console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching products:", error.message);
    dispatch({
      type: "FETCH_PRODUCT_DROPDOWNVALUES_FAILED",
      payload: error,
    });
    throw error;
  }
};

export const addNewSeason = (newSeason) => async (dispatch) => {
  try {
    dispatch({ type: "ADD_NEW_SEASON_REQUEST" });

    const response = await axios.put(
      `${server}/product/addnew-season`,
      {
        newSeason,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    dispatch({
      type: "ADD_NEW_SEASON_SUCCESS",
      payload: response.data,
    });

    return response.data;
  } catch (error) {
    console.error("Error adding new season:", error.message);
    dispatch({
      type: "ADD_NEW_SEASON_FAILED",
      payload: error,
    });
    throw error;
  }
};

export const addNewBrand = (newBrand) => async (dispatch) => {
  try {
    dispatch({ type: "ADD_NEW_BRAND_REQUEST" });

    const response = await axios.put(
      `${server}/product/addnew-brand`,
      {
        newBrand,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    dispatch({
      type: "ADD_NEW_BRAND_SUCCESS",
      payload: response.data,
    });

    return response.data;
  } catch (error) {
    console.error("Error adding new brand:", error.message);
    dispatch({
      type: "ADD_NEW_BRAND_FAILED",
      payload: error,
    });
    throw error;
  }
};

export const addNewDepartment = (newDepartment) => async (dispatch) => {
  try {
    dispatch({ type: "ADD_NEW_DEPARTMENT_REQUEST" });

    const response = await axios.put(
      `${server}/product/addnew-department`,
      {
        newDepartment,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    dispatch({
      type: "ADD_NEW_DEPARTMENT_SUCCESS",
      payload: response.data,
    });

    return response.data;
  } catch (error) {
    console.error("Error adding new brand:", error.message);
    dispatch({
      type: "ADD_NEW_DEPARTMENT_FAILED",
      payload: error,
    });
    throw error;
  }
};

export const getTrendBrands = (id, role) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_TREND_BRAND_REQUEST" });
    const data = await axios.get(
      `${server}/product/getbrands?id=${id}&role=${role}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    dispatch({
      type: "FETCH_TREND_BRAND_SUCCESS",
      payload: data,
    });

    console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching products:", error.message);
    dispatch({
      type: "FETCH_TREND_BRAND_FAILED",
      payload: error,
    });
    throw error;
  }
};

export const getBrandDetails = (trend, id) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_BRAND_REQUEST" });
    const data = await axios.get(
      `${server}/product/getbranddetails?trend=${trend}&id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    dispatch({
      type: "FETCH_BRAND_SUCCESS",
      payload: data.data,
    });

    console.log(data);
    return data.data;
  } catch (error) {
    console.error("Error fetching products:", error.message);
    dispatch({
      type: "FETCH_BRAND_FAILED",
      payload: error,
    });
    throw error;
  }
};

export const getTrendBrandDetails =
  (id, role, trend, brand) => async (dispatch) => {
    try {
      dispatch({ type: "FETCH_TREND_REQUEST" });
      const data = await axios.get(
        `${server}/product/gettrenddetails?id=${id}&role=${role}&trend=${trend}&brand=${brand}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      dispatch({
        type: "FETCH_TREND_SUCCESS",
        payload: data,
      });

      console.log(data);
      return data;
    } catch (error) {
      console.error("Error fetching products:", error.message);
      dispatch({
        type: "FETCH_TREND_FAILED",
        payload: error,
      });
      throw error;
    }
  };

export const getBestSeller = (id, role) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_BESTSELLER_REQUEST" });
    const data = await axios.get(
      `${server}/product/getbestsellers?id=${id}&role=${role}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    dispatch({ type: "FETCH_BESTSELLER_SUCCESS", payload: data });

    console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching products:", error.message);
    dispatch({
      type: "FETCH_BESTSELLER_FAILED",
      payload: error,
    });
    throw error;
  }
};

export const setGender = (gender) => ({
  type: "SET_GENDER",
  payload: gender,
});
export const convertToPdf = (arrayBuffer) => async (dispatch) => {
  try {
    dispatch({ type: "ADD_ARRAYBUFFER_REQUEST" });

    // Convert ArrayBuffer to Uint8Array for JSON serialization
    const uint8Array = new Uint8Array(arrayBuffer);

    const { data } = await axios.post(
      `${server}/product/convert-to-pdf`,
      { arrayBuffer: Array.from(uint8Array) },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    console.log(data);
    dispatch({ type: "ADD_ARRAYBUFFER_SUCCESS", payload: data });
    return true;
  } catch (error) {
    dispatch({
      type: "ADD_ARRAYBUFFER_FAILED",
      payload: error.response.data.message,
    });
    return false;
  }
};

export const setVisibleCount = (count) => ({
  type: "SET_VISIBLE_COUNT",
  payload: count,
});

export const setLastViewedProduct = (productId) => ({
  type: "SET_LAST_VIEWED_PRODUCT",
  payload: productId,
});
