import React, { useCallback, useEffect, useRef, useState } from "react";
import PptxGenJS from "pptxgenjs";
import ProductPreview from "./ProductPreview";
import { connect } from "react-redux";
import { setSelectedFilteredProducts } from "../redux/action/others";
import { useNavigate } from "react-router-dom";
import { HiArrowLongLeft } from "react-icons/hi2";
import { getProductsFromIndexedDB } from "../components/IndexDB";
import bestSellerIcon from "../images/icons/bestSeller.svg";
import { pptLabel_base64,pptFirstPage ,ppt_plus_icon,ppt_pearl_label,ppt_text_label ,ppt_hollow,ppt_last_page} from "../images/PptLabel";
import { bestSellerBase64 } from "../images/icons/BestSellerIconBase64";
import html2canvas from "html2canvas";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { server } from "../redux/store";
import convertDate from "../components/Helper/ConvertDate";
import { RiArrowDropDownLine } from "react-icons/ri";
import pptPearlGlobalText from "../images/ppt-pearl-global-text.svg"
import pptHollow from "../images/ppt-hollow.svg"
import plusIcon from  "../images/plus-icon.svg";
import dayjs from "dayjs";
import {getImageDimensions,getProductImageDimensions,getAllProductImageDimensions} from "./ProductImageDimensionCalculator"
// import { convertToPdf } from '../redux/action/addProduct';
const CHUNK_SIZE = 100000; // 100KB
function PptGenerator({
  selectedFilteredProductsData,
  setActiveItem,
  setDemoSelected,
}) {
  const [pptDate, setPptDate] = useState(dayjs().format('YYYY-MM-DD HH:mm:ss'));
  const viewRef = useRef(null);
  const [selectedFilteredProducts, setSelectedFilteredProducts] = useState([]);
  const [pptBase64String, setPptBase64String] = useState(null);
  const [selectedProductIndex, setSelectedProductIndex] = useState(0);
  const [selectedSlideIndex, setSelectedSlideIndex] = useState(-1);
  const [selectedDocType, setSelectedDocType] = useState("");
  const [generate, setGenerate] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false); // Initialize to 0 for the first slide
  const [lastSlideVisible,setLastSlideVisible]=useState(false);
  const [firstSlideVisible,setFirstSlideVisible]=useState(true);
  const navigate = useNavigate();
  const getProductsFromIndexedDb = async () => {
    try {
      // Retrieve products from IndexedDB
      const storedProducts = await getProductsFromIndexedDB(
        "productsDB",
        "products",
        1
      );
      console.log("Retrieved products:", storedProducts);
      setSelectedFilteredProducts(storedProducts);
      return storedProducts;
      // Now you can add more operations like displaying the retrieved products
    } catch (error) {
      console.error("Error retrieving products:", error);
      return []; // Return an empty array or handle the error as needed
    }
  };
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  useEffect(() => {
    getProductsFromIndexedDb();
  }, []);
  console.log(selectedFilteredProducts);
  getAllProductImageDimensions(selectedFilteredProducts).then((results) => {
    console.log(results);
});
const handleGeneratePpt = useCallback(async () => {
    if (!generate) return;
  
    // Get image dimensions first
    const imageDimensions = await getAllProductImageDimensions(selectedFilteredProducts);
  
    const pptx = new PptxGenJS();
    const slide0 = pptx.addSlide();
  
    slide0.addImage({
      data: pptFirstPage,
      x: "0%",
      y: "0%",
      w: "100%",
      h: "100%",
    });
    slide0.addImage({
      data: ppt_text_label,
      x: "25%",
      y: "45%",
      w: "50%",
      h: "20%",
      sizing: { type: "contain" },
    });
    slide0.addImage({
      data: ppt_plus_icon,
      x: "90%",
      y: "80%",
      w: "5%",
      h: "8%",
      sizing: { type: "contain" },
    });
    slide0.addImage({
      data: ppt_hollow,
      x: "33%",
      y: "0%",
      w: "33%",
      h: "33%",
      sizing: { type: "contain" },
    });
    slide0.addText(`Ppt Generated on ${dayjs().format("YYYY-MM-DD HH:mm:ss")}`, {
      x: "2%",
      y: "90%",
      w: "20%",
      h: "5%",
      fontSize: 6.5,
      color: "8aaa78",
    });
  
    selectedFilteredProducts.forEach((product, index) => {
      const productDimensions = imageDimensions.find(
        (dim) => dim._id === product._id
      );
  
      // Slide 1: Front image and details
      const slide1 = pptx.addSlide();
  
      slide1.addText("THIS PRESENTATION IS CREATED BY PEARL AI PLATFORM", {
        x: "2%",
        y: "95%",
        w: "20%",
        h: "5%",
        fontSize: 4,
      });
  
      slide1.addImage({
        data: ppt_plus_icon,
        x: "90%",
        y: "80%",
        w: "5%",
        h: "8%",
        sizing: { type: "contain" },
      });
  
      slide1.addImage({
        data: ppt_pearl_label,
        x: "85%",
        y: "95%",
        w: "10%",
        h: "3%",
        sizing: { type: "contain" },
      });
  
      const calculateHeightAndYPosition = (imageSize) => {
        // A4 landscape dimensions (width: 1920px, height: 1080px)
        const slideWidth = 1920;
        const slideHeight = 1080;
      
        // Calculate the aspect ratio of the image
        const imageAspectRatio = imageSize.height / imageSize.width;
      
        // Calculate the height percentage relative to the slide's actual height
        const heightPercentage = (imageAspectRatio * slideWidth * 24) / slideHeight;
      
        // Calculate the vertical centering position
        const yPosition = `${(100 - heightPercentage) / 2}%`;
      
        return { w: "24%", h: `${heightPercentage}%`, y: yPosition };
      };
      
      // For the front image
      const frontImageSize = productDimensions.dimensions.frontImage;
      const frontDimensions = calculateHeightAndYPosition(frontImageSize);
      slide1.addImage({
        data: product.front_image_base64,
        x: "2%",
        ...frontDimensions,
        sizing: {
          type: "contain",
          x: "2%",
          ...frontDimensions,
        },
      });
      
      // For the back image
      const backImageSize = productDimensions.dimensions.backImage;
      const backDimensions = calculateHeightAndYPosition(backImageSize);
      slide1.addImage({
        data: product.back_image_base64,
        x: "27%",
        ...backDimensions,
        sizing: {
          type: "contain",
          x: "2%",
          ...backDimensions,
        },
      });
      if (product.best_seller) {
        slide1.addImage({
          data: bestSellerBase64,
          x: "5%",
          y: "-1%",
          sizing: { type: "contain", x: "88%", y: "-%", w: "5%", h: "12%" },
        });
      }
  
      const closeUpImagePositions = [
        { x: "52%", y: "10%", w: "22%", h: "25%" },
        { x: "77%", y: "10%", w: "22%", h: "30%" },
      ];
  
      if (productDimensions.dimensions.closeupImages?.length) {
        productDimensions.dimensions.closeupImages.forEach((img, idx) => {
          const pos = closeUpImagePositions[idx];
          slide1.addImage({
            data: product.closeup_images_base64[idx],
            x: pos.x,
            y: pos.y,
            w: pos.w,
            h: pos.h,
            sizing: { type: "contain" }, // Maintain aspect ratio
          });
        });
      }
  
      const details = [
        { label: "Description", value: product.description },
        { label: "Season", value: product.season },
        { label: "Style", value: product.product_style },
        { label: "Gender", value: product.gender },
        { label: "Categories", value: product.categories },
        { label: "Fabric Reference No.", value: product.fabric_ref },
        { label: "Content", value: product.content },
        { label: "Construction", value: product.construction },
        { label: "Fabric Mill/Supplier", value: product.fabric_supplier },
        { label: "Shrinkage", value: product.shrinkage },
        { label: "Weight", value: product.weight },
        { label: "Cuttable Width", value: product.cw },
        { label: "Fabric Price ($)", value: product.fabric_price },
        { label: "Fabric FOB (USD)", value: product.fob },
        { label: "Phase", value: product.phase },
        { label: "Division", value: product?.division },
        { label: "Customer", value: product?.customer },
        { label: "Shipment Date", value: product.shipment_date },
        { label: "Order Quantity", value: product.order_quantity },
        { label: "Yardage", value: product?.yardage },
        { label: "Garment FOB (USD)", value: product.garment_fob },
      ];
  
      let yPosition = 0.1;
      let xPositions = [5, 7.5]; // Positions for the left and right columns
      let columnIndex = 0;
      if (!productDimensions.dimensions.closeupImages?.length) {
        yPosition = 0.1;
      } else {
        yPosition = 2;
      }
  
      // Process the main details
      details.forEach((detail) => {
        if (detail.value) {
          const x = xPositions[columnIndex];
          slide1.addText(detail.label, {
            x,
            y: yPosition,
            w: 4,
            h: 0.5,
            fontSize: 7,
            valign: "middle", 
          });
          slide1.addText(detail.value, {
            x,
            y: yPosition + 0.2,
            w: 2.5,
            h: 0.5,
            fontSize: 8,
            bold: true,
            valign: "middle", 
          });
  
          // Add a line below each value
          slide1.addShape(pptx.shapes.LINE, {
            x: x,
            y: yPosition + 0.6,
            w: 2,
            h: 0.0,
            line: { color: "C6C6D0", width: 0.2 ,dashType: "dash",h: 0.5},
          });
  
          // Move to the next position
          columnIndex = (columnIndex + 1) % 2;
          if (columnIndex === 0) {
            yPosition += 0.5;
          }
        }
      });
  
      // Slide 2: Back image and closeup images
    });
  
    const slide2 = pptx.addSlide();
  
    slide2.addImage({
      data: ppt_last_page,
      x: "0%",
      y: "0%",
      w: "100%",
      h: "100%",
    });
    slide2.addImage({
      data: ppt_text_label,
      x: "25%",
      y: "45%",
      w: "50%",
      h: "20%",
      sizing: { type: "contain" },
    });
    slide2.addImage({
      data: ppt_plus_icon,
      x: "90%",
      y: "80%",
      w: "5%",
      h: "8%",
      sizing: { type: "contain" },
    });
    slide2.addImage({
      data: ppt_hollow,
      x: "33%",
      y: "0%",
      w: "33%",
      h: "33%",
      sizing: { type: "contain" },
    });
  
    console.log(selectedDocType);
    if (selectedDocType === "ppt") {
      pptx.writeFile({ fileName: "presentation.pptx",compression:true });
    } else if (selectedDocType === "pdf") {
      const pptBase64 = await pptx.write("base64");
      console.log(pptBase64);
  
      // Send the base64 string to the backend for conversion
      const sessionId = uuidv4();
  
      // Send the base64 string in chunks
      const success = await sendInChunks(pptBase64, sessionId);
      if (success) {
        console.log("PPT successfully converted to PDF");
      } else {
        console.error("Error converting PPT to PDF");
      }
    }
    setGenerate(false); // Reset the trigger state
  }, [selectedDocType, generate]);


  const sendInChunks = async (base64String, sessionId) => {
    try {
      const totalChunks = Math.ceil(base64String.length / CHUNK_SIZE);

      for (let i = 0; i < totalChunks; i++) {
        const chunk = base64String.slice(i * CHUNK_SIZE, (i + 1) * CHUNK_SIZE);
        await axios.post(
          `${server}/product/upload-chunk`,
          { chunk, index: i, totalChunks, sessionId },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
      }

      // Signal the server to start conversion
      const { data } = await axios.post(
        `${server}/product/convert-to-pdf`,
        { sessionId },
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "arraybuffer",
          withCredentials: true,
        }
      );

      // Handle the received PDF data
      const blob = new Blob([data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "converted.pdf";
      document.body.appendChild(a);
      a.click();
      a.remove();
      return true;
    } catch (error) {
      console.error("Error converting PPT to PDF:", error);
      return false;
    }
  };
  console.log(pptBase64String);
  useEffect(() => {
    handleGeneratePpt();
  }, [generate, handleGeneratePpt]);
  const handleBackButtonClick = (event) => {
    localStorage.setItem("activeItem", "");
    setActiveItem("");
    setDemoSelected(false);
    event.preventDefault();
    navigate("/dashboard");
  };

  const handleSlideClick = (productIndex, slideIndex) => {
    try{
      setLastSlideVisible(false);
      setFirstSlideVisible(false);
      setSelectedProductIndex(productIndex);
      setSelectedSlideIndex(slideIndex);
    }
    catch(error){
      console.log(error)
      return
    }  
  };
  const handlePptButtonClick = () => {
    setSelectedDocType("ppt");
    setGenerate(true); // Trigger the generation
  };

  const handlePdfButtonClick = () => {
    setSelectedDocType("pdf");
    setGenerate(true); // Trigger the generation
  };
  console.log(selectedFilteredProducts);
  return (
    <div className="product-preview-container">
      <div className="product-preview-header">
        <div className="back-button-container">
          <button className="ppt-back-button" onClick={handleBackButtonClick}>
            <HiArrowLongLeft />
          </button>
          <span className="ppt-title-div">Presentation</span>
        </div>
        <div className="dropdown">
          <button onClick={toggleDropdown} className="dropbtn">
            Download
            <RiArrowDropDownLine />
          </button>
          {dropdownOpen && (
            <div className="dropdown-content-ppt">
              {/* Download PPT button */}
              <button onClick={handlePptButtonClick}>PPT</button>
              {/* Download PDF button */}
              <button onClick={handlePdfButtonClick}>PDF</button>
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          height: "calc(100vh - 80px  - 16px)",
          gap: "1rem",
          width: "100vw",
        }}
      >
        <div
          style={{
            padding: "20px",
            background: "rgb(250, 248, 255)",
            gap: "1rem",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflowY: "scroll",
            width: "15%",
          }}
        >
          <div
            className={`first-slide ${firstSlideVisible ? "active-slide" : ""}`}
            onClick={() => {
              setFirstSlideVisible(true);
              setLastSlideVisible(false);
            }}
          >
                  <img className="pearl-text-img" src={pptPearlGlobalText} />
                  <img className="ppt-plus-icon" src={plusIcon}/>
                  <img className="ppt-hollow-icon" src={pptHollow}/>
                  <span className="ppt-corner-text preview">{pptDate}</span>
          </div>
          {selectedFilteredProducts.map((product, productIndex) => (
            <div
              style={{ gap: "1rem", display: "flex", flexDirection: "column" }}
              key={productIndex}
            >
              <ProductPreview
                product={product}
                onSlideClick={(slideIndex) =>
                  handleSlideClick(productIndex, slideIndex)
                }
                activeSlide={
                  productIndex === selectedProductIndex
                    ? selectedSlideIndex
                    : null
                }
              />
            </div>
          ))}
          <div
            className={`last-slide ${lastSlideVisible ? "active-slide" : ""}`}
            onClick={() => {
              setLastSlideVisible(true);
              setFirstSlideVisible(false);
            }}
          >
                  <img className="pearl-text-img" src={pptPearlGlobalText} />
                  <img className="ppt-plus-icon" src={plusIcon}/>
                  <img className="ppt-hollow-icon" src={pptHollow}/>
          </div>
        </div>
        <div className="product-main-slide-container">
          <div
            ref={viewRef}
            style={{ flex: 1, padding: "12px", width: "100%", height: "100%" }}
          >
            {firstSlideVisible && (
              <div className="slide">
                <div className={`first-slide view`}>
                <img className="pearl-text-img" src={pptPearlGlobalText} />
                  <img className="ppt-plus-icon" src={plusIcon}/>
                  <img className="ppt-hollow-icon" src={pptHollow}/>
                  <span className="ppt-corner-text generated-date other-slides">{`Ppt Generated on${dayjs().format('YYYY-MM-DD HH:mm:ss')}`}</span>
                </div>
              </div>
            )}
            {selectedProductIndex !== null &&
              !lastSlideVisible &&
              !firstSlideVisible && (
                <div className="slide">
                  <ProductPreview
                    product={selectedFilteredProducts[selectedProductIndex]}
                    activeSlide={selectedSlideIndex}
                    isMainDisplay
                    products={selectedFilteredProducts}
                  />
                </div>
              )}
            {lastSlideVisible && (
              <div className="slide">
                <div className={`last-slide view`}>
                  <img className="pearl-text-img" src={pptPearlGlobalText} />
                  <img className="ppt-plus-icon" src={plusIcon}/>
                  <img className="ppt-hollow-icon" src={pptHollow}/>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  selectedFilteredProductsData: state.selectedFilteredProducts.filteredProducts,
});

const mapDispatchToProps = {
  setSelectedFilteredProducts,
};

export default connect(mapStateToProps, mapDispatchToProps)(PptGenerator);
// old ppt code with 2 page format and first apge included
// const handleGeneratePpt = useCallback(async () => {
//   if (!generate) return;
//   const pptx = new PptxGenJS();
//   const slide0 = pptx.addSlide();
//   slide0.addImage({
//     data: pptFirstPage,
//     x: "0%",
//     y: "0%",
//     w: "100%",
//     h: "100%",
//   });
//   selectedFilteredProducts.forEach((product) => {
//     // Slide 1: Front image and details
//     const slide1 = pptx.addSlide();
//     // slide1.addText('Fabric Info', { x: 0.5, y: 0.2, w: 9, h: 0.5, fontSize: 24, bold: true, align: 'center' });
//     slide1.addImage({
//       data: pptLabel_base64,
//       x: "0%",
//       y: "0%",
//       w: 3,
//       h: "100%",
//     });
//     slide1.addShape(pptx.shapes.RECTANGLE, {
//       x: "15%",
//       y: "10%",
//       w: "24%",
//       h: "80%",
//       line: { color: "CDE5FF", width: 1 }, // Black border with width 2
//       fill: { color: "FFFFFF" }, // White fill
//     });
//     slide1.addImage({
//       data: product.front_image_base64,
//       x: "15%",
//       y: "10%",
//        w: "24%", h: "80%",
//       sizing: {type:"contain" ,x: "15%", y: "20%", },
//     });
//     const details = [
//       { label: "Description", value: product.description },
//       { label: "Season", value: product.season },
//       { label: "Style", value: product.product_style },
//       { label: "Gender", value: product.gender },
//       { label: "Categories", value: product.categories },
//       { label: "Fabric Reference No.", value: product.fabric_ref },
//       { label: "Content", value: product.content },
//       { label: "Construction", value: product.construction },
//       { label: "Fabric Mill/Supplier", value: product.fabric_supplier },
//       { label: "Shrinkage", value: product.shrinkage },
//       { label: "Weight", value: product.weight },
//       { label: "Cuttable Width", value: product.cw },
//       { label: "Fabric Price ($)", value: product.fabric_price },
//       { label: "Fabric FOB (USD)", value: product.fob },
//     ];
//     const phaseDetails = [
//       { label: "Phase", value: product.phase },
//       { label: "Shipment Date", value: product.shipment_date },
//       { label: "Order Quantity", value: product.order_quantity },
//       { label: "Garment FOB (USD)", value: product.garment_fob },
//     ];

//     // Left and right columns
//     slide1.addText("Fabric Info", {
//       x: 4.5,
//       y: 0.3,
//       fontSize: 12,
//       bold: true,
//       color: "26408A",
//     });

//     let yPosition = 0.4;
//     const xPositions = [4.5, 7]; // Positions for the left and right columns
//     let columnIndex = 0;

//     // Process the main details
//     details.forEach((detail) => {
//       if (detail.value) {
//         const x = xPositions[columnIndex];
//         slide1.addText(detail.label, {
//           x,
//           y: yPosition,
//           w: 4,
//           h: 0.5,
//           fontSize: 10,
//         });
//         slide1.addText(detail.value, {
//           x,
//           y: yPosition + 0.2,
//           w: 4,
//           h: 0.5,
//           fontSize: 10,
//           bold: true,
//         });

//         // Add a line below each value
//         slide1.addShape(pptx.shapes.LINE, {
//           x: x,
//           y: yPosition + 0.6,
//           w: 2,
//           h: 0,
//           line: { color: "C6C6D0", width: 1 },
//         });

//         // Move to the next position
//         columnIndex = (columnIndex + 1) % 2;
//         if (columnIndex === 0) {
//           yPosition += 0.5;
//         }
//       }
//     });

//     // Adding 'Phase' text at the correct position if any phase details exist
//     if (phaseDetails.some((detail) => detail.value)) {
//       let columnIndex = 0;
//       const phaseYPosition = yPosition + 0.4;
//       slide1.addText("Phase", {
//         x: 4.5,
//         y: phaseYPosition,
//         fontSize: 12,
//         bold: true,
//         color: "26408A",
//       });

//       yPosition = phaseYPosition + 0.05; // Update yPosition to start just below the "Phase" text

//       // Process the phase details
//       phaseDetails.forEach((detail) => {
//         if (detail.value) {
//           const x = xPositions[columnIndex];
//           slide1.addText(detail.label, {
//             x,
//             y: yPosition,
//             w: 4,
//             h: 0.5,
//             fontSize: 10,
//             color: "45464F",
//           });
//           if (detail.label === "Shipment Date") {
//             slide1.addText(convertDate(detail.value), {
//               x,
//               y: yPosition + 0.2,
//               w: 4,
//               h: 0.5,
//               fontSize: 10,
//               bold: true,
//               color: "1A1B21",
//             });
//           } else {
//             slide1.addText(detail.value, {
//               x,
//               y: yPosition + 0.2,
//               w: 4,
//               h: 0.5,
//               fontSize: 10,
//               bold: true,
//               color: "1A1B21",
//             });
//           }
//           // Add a line below each value
//           slide1.addShape(pptx.shapes.LINE, {
//             x: x,
//             y: yPosition + 0.6,
//             w: 2,
//             h: 0,
//             line: { color: "C6C6D0", width: 1 },
//           });

//           // Move to the next position
//           columnIndex = (columnIndex + 1) % 2;
//           if (columnIndex === 0) {
//             yPosition += 0.5;
//           }
//         }
//       });
//     }

//     // Slide 2: Back image and closeup images
//     const slide2 = pptx.addSlide();

//     const margin = "1%";
//     const totalImages = product.closeup_images_base64
//       ? product.closeup_images_base64.length
//       : 0;

//     if (totalImages === 0) {
//       // No close-up images
//       slide2.addShape(pptx.shapes.RECTANGLE, {
//         x: "1%",
//         y: margin,
//         w: "98%",
//         h: "98%",
//         line: { color: "CDE5FF", width: 1 }, // Border color and width
//         fill: { color: "EFEFEF" }, // Fill color
//       });
//       slide2.addImage({
//         data: product.back_image_base64,
//         x: "36%",
//         y: margin,
//         w: "24%", h: "98%",
//         sizing: { type: "contain", x: "1%", y: margin,  }, // Maintain aspect ratio
//       });
//     } else if (totalImages === 1) {
//       // One close-up image
//       const closeupPos = { x: "1%", y: margin, w: "48%", h: "98%" };
//       slide2.addShape(pptx.shapes.RECTANGLE, {
//         x: closeupPos.x,
//         y: closeupPos.y,
//         w: closeupPos.w,
//         h: closeupPos.h,
//         line: { color: "CDE5FF", width: 1 }, // Border color and width
//         fill: { color: "EFEFEF" }, // Fill color
//       });

//       slide2.addImage({
//         data: product.closeup_images_base64[0],
//         x: closeupPos.x,
//         y: "24%",
//         h:"50%",w:"48%",
//         sizing: { type: "contain",x: closeupPos.x,y: closeupPos.y }, // Maintain aspect ratio
//       });
//       slide2.addShape(pptx.shapes.RECTANGLE, {
//         x: "51%",
//         y: margin,
//         w: "48%",
//         h: "98%",
//         line: { color: "CDE5FF", width: 1 }, // Border color and width
//         fill: { color: "EFEFEF" }, // Fill color
//       });
//       slide2.addImage({
//         data: product.back_image_base64,
//         x: "62%",
//         y: margin,w: "24%", h: "98%",
//         sizing: { type: "contain", x: "51%", y: margin,  }, // Maintain aspect ratio
//       });
//     } else if (totalImages === 2) {
//       // Two close-up images
//       const closeupPositions = [
//         { x: "1%", y: margin, w: "32%", h: "98%" },
//         { x: "34%", y: margin, w: "32%", h: "98%" },
//       ];
//       const closeUpImagePositions=[
//         { x: "1%", y: "24%", w: "32%", h: "45%" },
//         { x: "34%", y: "24%", w: "32%", h: "45%" },
//       ]
//       closeupPositions.forEach((pos, idx) => {
//         slide2.addShape(pptx.shapes.RECTANGLE, {
//           x: pos.x,
//           y: pos.y,
//           w: pos.w,
//           h: pos.h,
//           line: { color: "CDE5FF", width: 1 }, // Border color and width
//           fill: { color: "EFEFEF" }, // Fill color
//         });
//       });
//       closeUpImagePositions.forEach((pos, idx) => {

//         slide2.addImage({
//           data: product.closeup_images_base64[idx],
//           x: pos.x,
//           y: pos.y,
//           w: "32%", h: pos.h,
//           sizing: { type: "contain", x: pos.x, y: pos.y }, // Maintain aspect ratio
//         });
//       });

//       slide2.addShape(pptx.shapes.RECTANGLE, {
//         x: "67%",
//         y: margin,
//         w: "32%",
//         h: "98%",
//         line: { color: "CDE5FF", width: 1 }, // Border color and width
//         fill: { color: "EFEFEF" }, // Fill color
//       });
//       slide2.addImage({
//         data: product.back_image_base64,
//         x: "67%",
//         y: margin,
//         w: "24%", h: "98%",
//         sizing: { type: "contain", x: "51%", y: margin }, // Maintain aspect ratio
//       });
//     } else {
//       // Three close-up images
//       const closeupPositions = [
//         { x: "1%", y: margin, w: "32%", h: "98%" },
//         { x: "34%", y: margin, w: "32%", h: "48%" },
//         { x: "34%", y: "51%", w: "32%", h: "48%" },
//       ];
//       const closeupImagePositions = [
//         { x: "1%", y: "30%", w: "32%", h: "38%" },
//         { x: "34%", y: "6%", w: "32%", h: "38%" },
//         { x: "34%", y: "56%", w: "32%", h: "38%" },
//       ];

//       closeupPositions.forEach((pos, idx) => {
//         slide2.addShape(pptx.shapes.RECTANGLE, {
//           x: pos.x,
//           y: pos.y,
//           w: pos.w,
//           h: pos.h,
//           line: { color: "CDE5FF", width: 1 }, // Border color and width
//           fill: { color: "EFEFEF" }, // Fill color
//         });
//       });
//       closeupImagePositions.forEach((pos, idx) => {
//         slide2.addImage({
//           data: product.closeup_images_base64[idx],
//           x: pos.x,
//           y: pos.y,
//           w: pos.w, h: pos.h,
//           sizing: { type: "contain", x: pos.x, y: pos.y  }, // Maintain aspect ratio
//         });
//       });

//       slide2.addShape(pptx.shapes.RECTANGLE, {
//         x: "67%",
//         y: margin,
//         w: "32%",
//         h: "98%",
//         line: { color: "CDE5FF", width: 1 }, // Border color and width
//         fill: { color: "EFEFEF" }, // Fill color
//       });
//       slide2.addImage({
//         data: product.back_image_base64,
//         x: "69%",
//         y: margin,
//         w: "28%", h: "98%",
//         sizing: { type: "contain", x: "69%", y: margin }, // Maintain aspect ratio
//       });
//     }
//     if (product.best_seller) {
//       slide1.addImage({
//         data: bestSellerBase64,
//         x: "88%",
//         y: "-1%",
//         sizing: { type: "contain", x: "88%", y: "-%", w: "5%", h: "12%" },
//       });
//       slide2.addImage({
//         data: bestSellerBase64,
//         x: "88%",
//         y: "-1%",
//         sizing: { type: "contain", x: "88%", y: "-1%", w: "5%", h: "12%" },
//       });
//     }
//   });
//   console.log(selectedDocType);
//   if (selectedDocType === "ppt") {
//     pptx.writeFile({ fileName: "presentation.pptx" });
//   } else if (selectedDocType === "pdf") {
//     const pptBase64 = await pptx.write("base64");
//     console.log(pptBase64);

//     // Send the base64 string to the backend for conversion
//     const sessionId = uuidv4();

//     // Send the base64 string in chunks
//     const success = await sendInChunks(pptBase64, sessionId);
//     if (success) {
//       console.log("PPT successfully converted to PDF");
//     } else {
//       console.error("Error converting PPT to PDF");
//     }
//   }
//   setGenerate(false); // Reset the trigger state
// }, [selectedDocType, generate]);