const initialState = {
  isLoading: false,
  isLoggedIn: false,
  errorMessage: null,
  userData: null,
};

// Reducer function
const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_REQUEST":
      return {
        ...state,
        isLoading: true,
        isLoggedIn: false,
        errorMessage: null,
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isLoggedIn: true,
        errorMessage: null,
        userData: action.payload,
      };
    case "LOGIN_FAILED":
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
        errorMessage: action.payload,
        userData: null,
      };
    default:
      return state;
  }
};

export default loginReducer;
