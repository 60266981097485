import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/login/Login";
import Dashboard from "./components/dashboard/Dashboard";
import { getUserDetailsFromLocalStorage } from "./components/LocalStorage";
import Product from "./components/addProduct/Product";
import AddTrend from "./components/addTrend/AddTrend";
import EditProduct from "./components/editProduct/EditProduct";
import TrendInfo from "./components/trendInfo/TrendInfo";
import ViewTrends from "./components/viewTrends/ViewTrends";
import ProductDetails from "./components/productView/ProductDetails";
import LandingPage from "./components/landingPage/LandingPage";
import PptGenerator from "./PPTGenerator/PptGenerator";
import Navbar from "./components/navbar/Navbar";
import EditBrand from "./components/editBrand/EditBrand";
import Profile from "./components/profile/Profile";
import SuperAdminProfile from "./components/superAdminProfile/SuperAdminProfile";
import CircularProgress from "@mui/material/CircularProgress";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Initialize with null
  const [ loading, setLoading] = useState(true); // Add a loading state
  const [selectedGender, setSelectedGender] = useState([]);
  const [activeItem, setActiveItem] = useState("");
  const [gender, setGender] = useState("");
  const [demoSelected, setDemoSelected] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const userDetails = getUserDetailsFromLocalStorage();
    if (userDetails) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
    setLoading(false); // Set loading to false after checking user details
  }, []);

  const handleLoginSuccess = (status) => {
    setIsAuthenticated(status);
  };

  const GradientCircularProgress = () => (
    <div className="loading-container">
      <React.Fragment>
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#26408A" />{" "}
              {/* Change color to #26408A */}
              <stop offset="50%" stopColor="#196D92" />{" "}
              {/* Change color to #196D92 */}
              <stop offset="100%" stopColor="#49883E" />{" "}
              {/* Change color to #49883E */}
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress
          sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
        />
      </React.Fragment>
    </div>
  );

  if (loading) {
    return <GradientCircularProgress />;
  }

  return (
    <BrowserRouter>
      {isAuthenticated && (
        <Navbar
          onLoginSuccess={handleLoginSuccess}
          selectedGender={selectedGender}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          gender={gender}
          setGender={setGender}
          setDemoSelected={setDemoSelected}
          demoSelected={demoSelected}
          products={products}
        />
      )}
      <Routes>
        {isAuthenticated ? (
          <>
            <Route
              path="/landingpage"
              element={
                <LandingPage
                  setSelectedGender={setSelectedGender}
                  setActiveItem={setActiveItem}
                />
              }
            />

            <Route
              path="/dashboard"
              element={
                <Dashboard
                  selectedGender={selectedGender}
                  setSelectedGender={setSelectedGender}
                  setActiveItem={setActiveItem}
                  setDemoSelected={setDemoSelected}
                  demoSelected={demoSelected}
                  products={products}
                  setProducts={setProducts}
                />
              }
            />
            <Route path="/addProduct" element={<Product />} />
            <Route path="/addtrend" element={<AddTrend />} />
            <Route path="/addtrend/:brand" element={<AddTrend />} />
            <Route path="/editbrand/:trend/:id" element={<EditBrand />} />
            <Route path="/editproduct/:productId" element={<EditProduct />} />
            <Route
              path="/generate-doc"
              element={
                <PptGenerator
                  setActiveItem={setActiveItem}
                  setDemoSelected={setDemoSelected}
                />
              }
            />
            <Route
              path="/productdetails/:productId"
              element={<ProductDetails />}
            />
            <Route path="/bransDetails/:trend/:brand" element={<TrendInfo />} />
            <Route path="/viewtrends" element={<ViewTrends />} />
            <Route
              path="/profile"
              element={<Profile onLoginSuccess={handleLoginSuccess} />}
            />
            <Route
              path="/super-admin-profile"
              element={
                <SuperAdminProfile onLoginSuccess={handleLoginSuccess} />
              }
            />

            <Route path="*" element={<Navigate to="/landingpage" />} />
            {/* Remove the default redirection to /landingpage */}
          </>
        ) : (
          <>
            <Route
              path="/"
              element={<Login onLoginSuccess={handleLoginSuccess} />}
            />
            <Route path="*" element={<Navigate to="/" />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
