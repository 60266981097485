import React, { useState, useEffect } from "react";
import "./SuperAdminProfile.css";
import ProfilePic from "../../images/icons/profile.svg";
import Button from "@mui/material/Button";
import { ExpandMore } from "@mui/icons-material";
import deletePopup from "../../images/icons/deletePopup.svg";
import CircularProgress from "@mui/material/CircularProgress";
import { getUserDetailsFromLocalStorage } from "../LocalStorage";
import { connect } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import editSvg from "../productView/icons/edit.svg";
import editGif from "../../images/icons/edit.gif";
import space from "../../images/empty-page-add.gif";
import Paper from "@mui/material/Paper";
import { TableVirtuoso } from "react-virtuoso";
import brandDelete from "../../images/icons/brandDelete.svg";
import brandEdit from "../../images/icons/brandEdit.svg";
import { Link, useNavigate } from "react-router-dom";
import search from "../../images/icons/search.svg";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import close from "../../images/icons/docClose.svg";
import TextField from "@mui/material/TextField";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { Toaster, toast } from "react-hot-toast";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  addUser,
  deleteUser,
  fetchUser,
  fetchUserDetails,
  editUser,
} from "../../redux/action/addUser";

const MenuProps = {
  PaperProps: {
    sx: {
      "& .MuiMenu-list": {
        paddingTop: "0px !important",
        paddingBottom: "0px !important",
        maxHeight: "250px",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: "0px",
        },
      },
    },
  },
};

const countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo, Democratic Republic of the",
  "Congo, Republic of the",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, North",
  "Korea, South",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

const SuperAdminProfile = ({
  fetchUserDetails,
  userDetails,
  addUser,
  fetchUser,
  deleteUser,
  user,
  onLoginSuccess,
  editUser,
}) => {
  const [adminDetails, setAdminDetails] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [openUserPopup, setOpenUserPopup] = useState(false);
  const [adminId, setAdminId] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [userId, setUserId] = useState("");
  const [edit, setEdit] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [editIsHovered, seteditIsHovered] = useState(false);
  const [showLoading, setShowLoading] = useState(true); // New state to control loading spinner

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    role: "",
    country: "",
    showPassword: false,
  });
  const navigate = useNavigate();

  const [newAdmin, setNewAdmin] = useState(false);

  console.log("filteredUsers", filteredUsers);

  useEffect(() => {
    const userDetails = getUserDetailsFromLocalStorage();
    if (userDetails) {
      setAdminDetails(userDetails?.user);
      fetchUserDetails(userDetails?.user?._id, "SuperAdmin");
      setAdminId(userDetails?.user?._id);
    }
    setTimeout(() => {
      setShowLoading(false);
    }, 500);
  }, [fetchUserDetails]);

  useEffect(() => {
    if (!userDetails?.users) {
      setFilteredUsers([]);
      return;
    }

    if (searchTerm === "") {
      setFilteredUsers(userDetails.users);
    } else {
      const filtered = userDetails.users.filter((user) =>
        user.username.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredUsers(filtered);
    }
  }, [searchTerm, userDetails?.users]);

  function createData(
    id,
    Name,
    Role,
    Country,
    email,
    JoinedDate,
    updatedDate,
    Edit
  ) {
    return { id, Name, Role, Country, email, JoinedDate, updatedDate, Edit };
  }

  const columns = [
    {
      width: 110,
      label: "Name",
      dataKey: "Name",
    },
    {
      width: 90,
      label: "Role",
      dataKey: "Role",
    },
    {
      width: 90,
      label: "Country",
      dataKey: "Country",
    },
    {
      width: 120,
      label: "Email Id",
      dataKey: "email",
    },
    {
      width: 90,
      label: "Date Joined",
      dataKey: "JoinedDate",
    },
    {
      width: 90,
      label: "Last Updated",
      dataKey: "updatedDate",
    },
    {
      width: 50,
      label: "",
      dataKey: "Edit",
      numeric: true,
    },
  ];

  const transformedData =
    filteredUsers?.map((item) => {
      const createdDate = new Date(item.createdAt)
        .toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        })
        .replace(/ /g, " ");
      const updatedDate = new Date(item.updatedAt)
        .toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        })
        .replace(/ /g, " ");
      return [
        item.firstname + " " + item.lastname,
        item.role,
        item.country,
        item.email,
        createdDate,
        updatedDate,
        item._id,
      ];
    }) || [];

  const rows = transformedData.map((data, index) => createData(index, ...data));

  console.log("rows", rows);

  const handleDeletePopup = async (brandId) => {
    setUserId(brandId);
    setOpenDelete(true);
  };

  const handleEditUserClick = async (userId) => {
    setUserId(userId);
    await fetchUser(userId);
    setEdit(true);
    console.log("userDetails", user?.user);

    setOpenUserPopup(true);
    //  navigate(`/editbrand/${trend}/${brandId}`);
  };

  const handleAdminClick = async (userId) => {
    setIsAdmin(true);
    setOpenUserPopup(true);
    //  navigate(`/editbrand/${trend}/${brandId}`);
  };

  useEffect(() => {
    if (edit && user?.user) {
      setFormData({
        firstname: user?.user?.firstname || "",
        lastname: user?.user?.lastname || "",
        email: user?.user?.email || "",
        country: user?.user?.country || "",
        password: "", // Leaving password empty for security reasons
        role: user?.user?.role || "",
        showPassword: false,
      });
    } else if (isAdmin && adminDetails) {
      setFormData({
        firstname: adminDetails.firstname || "",
        lastname: adminDetails.lastname || "",
        email: adminDetails.email || "",
        country: user?.user?.country || "",
        password: "", // Leaving password empty for security reasons
        role: adminDetails.role || "",
        showPassword: false,
      });
    }
  }, [adminDetails, edit, isAdmin, user, userId]);

  const VirtuosoTableComponents = {
    Scroller: React.forwardRef((props, ref) => (
      <TableContainer component={Paper} {...props} ref={ref} />
    )),
    Table: (props) => (
      <Table
        {...props}
        sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
      />
    ),
    TableHead,
    TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
    TableBody: React.forwardRef((props, ref) => (
      <TableBody {...props} ref={ref} />
    )),
  };
  const togglePasswordVisibility = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      showPassword: !prevFormData.showPassword,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  console.log("formdata", formData);
  function fixedHeaderContent() {
    return (
      <TableRow
        sx={{
          height: "40px",
        }}
      >
        {columns.map((column) => (
          <TableCell
            key={column.dataKey}
            variant="head"
            align={column.numeric || false ? "right" : "left"}
            style={{ width: column.width }}
            sx={{
              backgroundColor: "background.paper",
              height: "60px",
              fontSize: "15px", // Customize the font size
              fontFamily: "CalibriBold",
            }}
          >
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    );
  }

  function rowContent(_index, row) {
    return (
      <React.Fragment>
        {columns.map((column) => (
          <TableCell
            sx={{
              fontSize: "16px", // Customize the font size
              fontFamily: "Calibri",
            }}
            key={column.dataKey}
            align={column.numeric || false ? "right" : "left"}
          >
            {column.dataKey === "Edit" ? (
              <div className="brand-buttons">
                <button
                  className="edit-button"
                  onClick={() => handleEditUserClick(row["Edit"])}
                >
                  <img
                    src={brandEdit}
                    alt={column.dataKey}
                    style={{ width: "24px", height: "24px" }}
                  />
                </button>
                <button
                  className="edit-button"
                  onClick={() => handleDeletePopup(row["Edit"])}
                >
                  <img
                    src={brandDelete}
                    alt={column.dataKey}
                    style={{ width: "24px", height: "24px" }}
                  />
                </button>
              </div>
            ) : (
              row[column.dataKey]
            )}
          </TableCell>
        ))}
      </React.Fragment>
    );
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };

  const handleSearchClick = () => {
    if (Array.isArray(rows) && rows.length > 0) {
      const filter = userDetails?.users?.filter((user) => {
        return user.username.toLowerCase().includes(searchTerm.toLowerCase());
      });

      setFilteredUsers(filter);
    } else {
      console.log("No users found.");
      setFilteredUsers([]);
    }
  };
  const handleCancel = () => {
    setFormData({
      firstname: "",
      lastname: "",
      country: "",
      email: "",
      password: "",
      role: "",
      showPassword: false,
    });
    setOpenUserPopup(false);
    setEdit(false);
    setIsAdmin(false);
    setNewAdmin(false);

    // navigate(`/productdetails/${productId}`);
  };
  console.log("formdata", formData);
  const handleCloseDeletePopup = () => {
    setOpenDelete(false);
  };

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleAdd = async () => {
    try {
      if (edit) {
        if (formData.password && !validatePassword(formData.password)) {
          toast.error(
            "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character."
          );
          return;
        }

        const response = await editUser({
          id: userId,
          firstname: formData.firstname,
          lastname: formData.lastname,
          username: formData.firstname + formData.lastname,
          email: formData.email,
          password: formData.password,
          country: formData.country,
          role: formData.role,
        });

        if (response.success) {
          toast.success(response.message);
          setOpenUserPopup(false);
          fetchUserDetails(adminId, "SuperAdmin");
          resetForm();
        } else {
          toast.error(response.message);
        }
      } else if (isAdmin) {
        if (formData.password || !validatePassword(formData.password)) {
          toast.error(
            "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character."
          );
          return;
        }

        if (formData.password && validatePassword(formData.password)) {
          const response = await editUser({
            id: adminDetails._id,
            password: formData.password,
          });

          if (response.success) {
            try {
              localStorage.removeItem("userDetails");
              toast.success(
                "You have been logged out. Redirecting to the Login page..."
              );

              setTimeout(() => {
                navigate("/");
                onLoginSuccess(false);
              }, 1000);
            } catch (error) {
              console.error("Logout failed:", error);
              toast.error("An error occurred during logout. Please try again.");
            }
          } else {
            toast.error(response.message);
          }
        }
      } else {
        if (!validatePassword(formData.password)) {
          toast.error(
            "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character."
          );
          return;
        }

        const response = await addUser({
          id: adminId,
          firstname: formData.firstname,
          lastname: formData.lastname,
          username: formData.firstname + formData.lastname,
          email: formData.email,
          password: formData.password,
          role: formData.role,
          country: formData.country,
        });

        if (response.success) {
          toast.success(response.message);
          setOpenUserPopup(false);
          fetchUserDetails(adminId, "SuperAdmin");
          resetForm();
        } else {
          toast.error(response.message);
        }
      }
    } catch (error) {
      console.error("API call failed:", error);
      toast.error(`An error occurred: ${error.message}`);
    }
  };

  const resetForm = () => {
    setFormData({
      firstname: "",
      lastname: "",
      email: "",
      country: "",
      password: "",
      role: "",
      showPassword: false,
    });
    setEdit(false);
    setIsAdmin(false);
    setNewAdmin(false);
  };

  console.log("edit", edit);

  const handleDelete = async () => {
    await deleteUser(userId);
    setOpenDelete(false);

    fetchUserDetails(adminId, "SuperAdmin");
    toast.success("User Deleted Sucessfully");
  };
  console.log("newAdmin", newAdmin);

  const GradientCircularProgress = () => (
    <div className="loading-container">
      <React.Fragment>
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#26408A" />
              <stop offset="50%" stopColor="#196D92" />
              <stop offset="100%" stopColor="#49883E" />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress
          sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
        />
      </React.Fragment>
    </div>
  );

  if (showLoading) {
    return <GradientCircularProgress />;
  }
  return (
    <div className="view-products">
      <Toaster />

      <Dialog
        open={openUserPopup}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "471px",
            height: isAdmin ? "200px" : "480px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflow: "hidden",
          },
        }}
        sx={{ "& .MuiDialogContent-root": { overflow: "hidden" } }}
      >
        <div className="adduser-nav">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            <span>
              {isAdmin
                ? "Change Password"
                : edit
                ? `Edit ${formData.firstname} ${formData.lastname}`
                : "Add User"}
            </span>
          </div>
          <div onClick={handleCancel} style={{ cursor: "pointer" }}>
            <img src={close} alt="" width={16} height={16} />
          </div>
        </div>
        <div style={{ padding: "24px", width: "100%", height: "400px" }}>
          {!isAdmin && (
            <div>
              <div
                style={{
                  display: "flex",
                  gap: "24px",
                  marginBottom: "24px",
                }}
              >
                <TextField
                  id="outlined-multiline-flexible"
                  name="firstname"
                  label="First Name"
                  multiline
                  fullWidth
                  maxRows={1}
                  value={formData.firstname}
                  onChange={handleChange}
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.8)",
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(0, 0, 0, 0.8)",
                    },
                  }}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  name="lastname"
                  label="Last Name"
                  multiline
                  fullWidth
                  maxRows={1}
                  value={formData.lastname}
                  onChange={handleChange}
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.8)",
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(0, 0, 0, 0.8)",
                    },
                  }}
                />
              </div>
              <TextField
                style={{ marginBottom: "24px" }}
                id="outlined-multiline-flexible"
                name="email"
                label="Email Id"
                multiline
                fullWidth
                maxRows={1}
                value={formData.email}
                onChange={handleChange}
                size="small"
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.8)",
                  },
                  "& .MuiInputLabel-root": {
                    color: "rgba(0, 0, 0, 0.8)",
                  },
                }}
              />
              <FormControl
                sx={{}}
                size="small"
                fullWidth
                style={{ marginBottom: "24px" }}
              >
                <InputLabel
                  htmlFor="demo-select-small"
                  sx={{
                    "&.MuiInputLabel-root": {
                      color: "rgba(0, 0, 0, 0.8)", // Change the label color to black
                    },
                  }}
                >
                  Role
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  name="role"
                  value={formData.role}
                  label="Role"
                  onChange={handleChange}
                  IconComponent={ExpandMore}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.8)", // Change the border color to black
                    },
                    "& .MuiSelect-icon": {
                      // Select the icon specifically
                      color: "#1A1B21", // Set the color of the icon
                    },
                  }}
                >
                  <MenuItem value="Admin">Admin</MenuItem>
                  <MenuItem value="Operator">Operator</MenuItem>
                  <MenuItem value="Marketing">Marketing</MenuItem>
                </Select>
              </FormControl>
              <FormControl
                sx={{}}
                size="small"
                fullWidth
                style={{ marginBottom: "35px" }}
              >
                <InputLabel
                  htmlFor="country-select-small"
                  sx={{
                    "&.MuiInputLabel-root": {
                      color: "rgba(0, 0, 0, 0.8)", // Change the label color to black
                    },
                  }}
                >
                  Country
                </InputLabel>
                <Select
                  labelId="country-select-small-label"
                  id="country-select-small"
                  name="country"
                  value={formData.country}
                  label="Country"
                  onChange={handleChange}
                  IconComponent={ExpandMore}
                  MenuProps={MenuProps}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.8)", // Change the border color to black
                    },
                    "& .MuiSelect-icon": {
                      // Select the icon specifically
                      color: "#1A1B21", // Set the color of the icon
                    },
                  }}
                >
                  {countries.map((country) => (
                    <MenuItem key={country} value={country}>
                      {country}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}

          <div style={{ position: "relative", marginBottom: "24px" }}>
            <TextField
              id="outlined-password-input"
              name="password"
              label="Password"
              type={formData.showPassword ? "text" : "password"}
              fullWidth
              value={formData.password}
              onChange={handleChange}
              size="small"
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(0, 0, 0, 0.8)",
                },
                "& .MuiInputLabel-root": {
                  color: "rgba(0, 0, 0, 0.8)",
                },
              }}
            />

            {formData.showPassword ? (
              <IoEyeOutline
                onClick={togglePasswordVisibility}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "50%",
                  width: "22px",
                  height: "22px",
                  right: "16px",
                  transform: "translateY(-50%)",
                }}
              />
            ) : (
              <IoEyeOffOutline
                onClick={togglePasswordVisibility}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "50%",
                  width: "22px",
                  height: "22px",
                  right: "16px",
                  transform: "translateY(-50%)",
                }}
              />
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "24px",
            }}
          >
            <Button
              onClick={handleCancel}
              sx={{
                position: "relative",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "16px",
                fontWeight: 600,
                color: "#26408A",
                padding: "0.5rem 1rem",
                cursor: "pointer",
                width: "126px",
                height: "40px",
                borderRadius: "20px",
                border: "1px solid transparent",
                backgroundImage: `linear-gradient(white, white), linear-gradient(to right, #26408A, #196D92, #49883E)`,
                backgroundOrigin: "padding-box, border-box",
                backgroundClip: "padding-box, border-box",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleAdd}
              autoFocus
              sx={{
                position: "relative",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "16px",
                fontWeight: 600,
                padding: "0.5rem 1rem",
                cursor: "pointer",
                width: "126px",
                height: "40px",
                borderRadius: "20px",
                border: "2px solid transparent",
                background:
                  "linear-gradient(to right, #26408A, #196D92,#49883E) padding-box,linear-gradient(to right, #26408A, #196D92,#49883E) border-box",
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Add
            </Button>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={openDelete}
        onClose={handleCloseDeletePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "502px",
            height: "333px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "32px 80px",
          },
        }}
        sx={{ "& .MuiDialogContent-root": { overflow: "hidden" } }}
      >
        <img src={deletePopup} alt="" width={100} height={100} />
        <DialogTitle id="alert-dialog-title">
          {"Are You Sure You Want to Delete ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This Product Will Be Deleted Immediately.
            <br /> You Can’t Undo This Action.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDeletePopup}
            sx={{
              position: "relative",
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",
              fontWeight: 600,
              color: "#26408A",
              padding: "0.5rem 1rem",
              cursor: "pointer",
              width: "140px",
              height: "40px",
              borderRadius: "20px",
              border: "2px solid transparent",
              backgroundImage: `linear-gradient(white, white), linear-gradient(to right, #26408A, #196D92, #49883E)`,
              backgroundOrigin: "padding-box, border-box",
              backgroundClip: "padding-box, border-box",
              marginRight: "24px",
              textTransform: "capitalize",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            autoFocus
            sx={{
              position: "relative",
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",
              fontWeight: 600,
              padding: "0.5rem 1rem",
              cursor: "pointer",
              width: "140px",
              height: "40px",
              borderRadius: "20px",
              border: "2px solid transparent",
              backgroundColor: "#BA1A1A",
              color: "#fff",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#BA1A1A",
                border: "2px solid #BA1A1A",
                textTransform: "capitalize",
              },
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <div className="profile-container">
        <div className="common">
          <div className="profile-card">
            <div className="profile-align">
              <div>
                <img src={ProfilePic} alt="" width={150} />
              </div>
              <div
                style={{
                  width: "calc(100% - 182px)",
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span className="profile-name">
                      {adminDetails.firstname + " " + adminDetails.lastname}
                    </span>{" "}
                    <button className="super-admin-button">Super Admin</button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12px",
                        fontFamily: "Calibri",
                        color: "rgba(118, 118, 128, 1)",
                      }}
                    >
                      Last Updated{" "}
                      {new Date(adminDetails.updatedAt)
                        .toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                        })
                        .replace(/ /g, " ")}
                    </span>{" "}
                    <button
                      className="edit-button-admin"
                      onMouseEnter={() => seteditIsHovered(true)}
                      onMouseLeave={() => seteditIsHovered(false)}
                      onClick={handleAdminClick}
                    >
                      <div className="view-product-buttons">
                        <div>
                          {!editIsHovered && (
                            <img
                              src={editSvg}
                              id="editIcon"
                              alt=""
                              width={24}
                              height={24}
                              style={{ margin: "0px 0px 3px 4px" }}
                            />
                          )}
                          {editIsHovered && (
                            <img
                              src={editGif}
                              id="editIcon"
                              alt=""
                              width={24}
                              height={24}
                              style={{ margin: "0px 0px 3px 4px" }}
                            />
                          )}
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
                <div className="profile-main-details">
                  <div className="box profile-details">
                    <span className="profile-header-text">Email Id</span>
                    <span className="profile-value-text">
                      {adminDetails.email}
                    </span>
                  </div>

                  <div className="profile-details3">
                    <div className="line"></div>
                    <div className="profile-details4">
                      <span className="profile-header-text">Password</span>
                      <span className="profile-value-text">******** </span>
                    </div>
                    <div className="line"></div>
                  </div>

                  <div className="profile-details2">
                    <div className="profile-details">
                      <span className="profile-header-text">Date Joined</span>
                      <span className="profile-value-text">
                        {new Date(adminDetails.createdAt)
                          .toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                          })
                          .replace(/ /g, " ")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="line-divide"></div>
          <div>
            {userDetails?.users?.length > 0 ? (
              <div>
                {" "}
                <div className="trends-header">
                  <div className="brand-header-text">
                    <div>
                      <span
                        style={{
                          fontFamily: "CalibriBold",
                          color: "rgba(38, 64, 138, 1)",
                          fontSize: "18px",
                        }}
                      >
                        User Records
                      </span>
                    </div>
                    <div className="count-circle">
                      <span className="brand-count">
                        {filteredUsers.length}
                      </span>
                    </div>
                  </div>
                  <div className="trends-header-buttons">
                    <div className="brand-search-bar">
                      <input
                        type="text"
                        placeholder="Search User"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        onKeyPress={handleKeyPress}
                      />
                      <img
                        src={search}
                        alt=""
                        className="brand-search-icon"
                        onClick={handleSearchClick}
                      />
                    </div>
                    <div>
                      <Link
                        className="add-user-button"
                        onClick={() => setOpenUserPopup(true)}
                      >
                        <span className="header-button-text">+ Add User</span>
                      </Link>
                    </div>
                  </div>
                </div>
                {filteredUsers?.length > 0 ? (
                  <Paper className="user-container">
                    <TableVirtuoso
                      data={rows}
                      components={VirtuosoTableComponents}
                      fixedHeaderContent={fixedHeaderContent}
                      itemContent={rowContent}
                    />
                  </Paper>
                ) : (
                  <div className="no-filter-users-container">
                    <div className="profile-filter-inner-content">
                      <div>
                        <img src={space} alt="" className="no-user-image1" />
                      </div>
                      <div>
                        <span className="no-product-text-big">
                          Seems Like No Users For this Search Here!!
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="no-filter-users-container1">
                <div className="profile-filter-inner-content1">
                  <div>
                    <img src={space} alt="" className="no-user-image2" />
                  </div>
                  <div>
                    <span className="no-product-text-big">
                      Seems Like No Users Here!!
                    </span>
                  </div>
                  <div>
                    <span className="no-product-text-small">
                      Let's Start Filling This Space With Your Users.
                    </span>
                  </div>
                  <div>
                    <Link
                      className="add-user-button"
                      onClick={() => setOpenUserPopup(true)}
                    >
                      <span className="header-button-text">+ Add User</span>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userDetails: state.userDetails.usersData,
  user: state.user.userData,
});

const mapDispatchToProps = {
  fetchUserDetails,
  fetchUser,
  addUser,
  deleteUser,
  editUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(SuperAdminProfile);
