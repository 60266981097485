  const buildDemo={
     selected: false,  
  }
  const selectedProducts={
    filteredProducts:[],
    loading:true,
    error:null,
  }
  const getBuildDemoStateReducer=(state=buildDemo, action) => {
    switch (action.type) {
        case "UPDATE_BUILD_DEMO_REQUEST":
          return {
            ...state,
            loading: true,
            error: null,
          };
        case "UPDATE_BUILD_DEMO_SUCCESS":
          return {
            ...state,
            loading: false,
            error: null,
            selected: action.payload,
          };
        case "UPDATE_BUILD_DEMO_FAILED":
          return {
            ...state,
            selected: false,
            error: action.payload,
          };
        default:
          return state;
      }
  }
  const setSelectedFilteredProductsReducer=(state=selectedProducts, action) => {
    switch (action.type) {
        case "SET_SELECTED_PRODUCT_REQUEST":
          return {
            ...state,
            loading: true,
            error: null,
          };
        case "SET_SELECTED_PRODUCT_SUCCESS":
          return {
            ...state,
            loading: false,
            error: null,
            filteredProducts: action.payload,
          };
        case "SET_SELECTED_PRODUCT_FAILED":
          return {
            ...state,
            error: action.payload,
          };
        default:
          return state;
      }
  }

  export {
    getBuildDemoStateReducer,
    setSelectedFilteredProductsReducer
  };
