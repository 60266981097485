export const updateBuildDemoStatus = (demo) => async (dispatch) => {
    try {
      dispatch({ type: "UPDATE_BUILD_DEMO_REQUEST" });
     
      dispatch({ type: "UPDATE_BUILD_DEMO_SUCCESS", payload: demo });
  

    } catch (error) {
      dispatch({
        type: "UPDATE_BUILD_DEMO_FAILED",
        payload: error,
      });
      throw error;
    }
  };
  export const setSelectedFilteredProducts = (productsData) => async (dispatch) => {
    try {
      dispatch({ type: "SET_SELECTED_PRODUCT_REQUEST" });
     
      dispatch({ type: "SET_SELECTED_PRODUCT_SUCCESS", payload: productsData });
  

    } catch (error) {
      dispatch({
        type: "SET_SELECTED_PRODUCT_FAILED",
        payload: error,
      });
      throw error;
    }
  };