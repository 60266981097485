import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import { login } from "../../redux/action/login";
import video from "../../images/pearlLoginVideo.mp4";
import pearl from "../../images/pearl.webm";
import logo from "../../images/Logo.svg";
import element1 from "../../images/element1.svg";
import element3 from "../../images/element1.svg";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import "./Login.css";

import {
  setUserDetailsInLocalStorage,
  getUserDetailsFromLocalStorage,
} from "../LocalStorage";
const Login = ({ login, onLoginSuccess }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const [status, setStatus] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const togglePasswordVisibility = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      showPassword: !prevFormData.showPassword,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Call the login action here and pass formData
      const userDetails = await login(formData.email, formData.password);
      setUserDetailsInLocalStorage(userDetails);
      const data = getUserDetailsFromLocalStorage();
      console.log("data", data);
      if (data?.user) {
        toast.success("Welcome to Pearl Global");
        setStatus(true);

        setTimeout(() => {
          navigate("/landingpage");
          onLoginSuccess(true);
        }, 1000);

        console.log("data inside", data);
      } else {
        toast.error("invlid Credentials");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div className="video-background">
      <video autoPlay muted loop id="video-bg">
        <source src={video} type="video/mp4" />
      </video>
      <div className="login-main">
        <img src={element1} alt="" className="element1" />
        <Toaster
          reverseOrder={false}
          toastOptions={{
            duration: 1000,
          }}
        />

        <div className="login-content">
          <div className="login-title">
            <div>
              <span className="small-span">An AI Platform</span>
            </div>
            <div>
              <div className="large-span">
                Elegant visuals, expertly
                <br /> curated through AI <br />
                innovation
              </div>
            </div>
            <div>
              <span className="small-span2">
                An online AI platform, exclusively encrypted for
                <br /> Pearl Global employees, organizing picture trends
                <br /> and more
              </span>
            </div>
          </div>
        </div>
        <div className="login1">
          <div className="login-container">
            <div>
              <img src={logo} alt="" className="logo" />
            </div>
            <div className="heading">
              <div className="login-hi">
                <span>Hi There </span>

                <video
                  autoPlay
                  muted
                  className="video-png"
                  // style={{ marginBottom: "5px" }}
                >
                  <source src={pearl} type="video/mp4" />
                </video>
              </div>
              <div className="login-span">
                Welcome to Pearl global. Enter your account details to Sign in.
              </div>
            </div>
            <div className="form-display">
              <form onSubmit={handleSubmit}>
                <div className="input-field">
                  <div>
                    <input
                      type="text"
                      placeholder="Email"
                      className="login-input"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>

                  <div style={{ position: "relative" }}>
                    <input
                      type={formData.showPassword ? "text" : "password"} // Toggle between 'text' and 'password' based on showPassword state
                      placeholder="Password"
                      className="login-input"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      style={{ paddingRight: "2.5rem" }} // Add padding on the right for the icon
                    />

                    {formData.showPassword ? (
                      <IoEyeOutline
                        onClick={togglePasswordVisibility}
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          top: "35%",
                          width: "24px",
                          height: "24px",
                          right: "16px",
                          transform: "translateY(-50%)",
                        }}
                      />
                    ) : (
                      <IoEyeOffOutline
                        onClick={togglePasswordVisibility}
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          top: "35%",
                          width: "24px",
                          height: "24px",
                          right: "16px",
                          transform: "translateY(-50%)",
                        }}
                      />
                    )}
                  </div>

                  <div>
                    <button type="submit" className="login-button">
                      <span className="login-button-text">LOGIN</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  login,
};

export default connect(null, mapDispatchToProps)(Login);

// <div>
//   <Link to="/dashboard" className="forgot-password-link">
//     <span>Forgot Password?</span>
//   </Link>
// </div>;
