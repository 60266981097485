const initialState = {
  loading: false,
  error: null,
  userAdded: false,
  userData: null,
};

const usersData = {
  loading: false,
  error: null,
  usersData: null,
};

const userData = {
  loading: false,
  error: null,
  userData: null,
};

const addUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_USER_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
        userAdded: false,
      };
    case "ADD_USER_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        userAdded: true,
        userData: action.payload,
      };
    case "ADD_USER_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
        userAdded: false,
      };
    default:
      return state;
  }
};

const fetchUsersReducer = (state = usersData, action) => {
  switch (action.type) {
    case "FETCH_USERS_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
        userAdded: false,
      };
    case "FETCH_USERS_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        usersData: action.payload,
      };
    case "FETCH_USERS_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const fetchUserReducer = (state = userData, action) => {
  switch (action.type) {
    case "FETCH_USER_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "FETCH_USER_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        userData: action.payload,
      };
    case "FETCH_USER_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export { addUserReducer, fetchUsersReducer, fetchUserReducer };
