export function getImageDimensions(base64) {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.src = base64;
    });
  }
  
  export async function getProductImageDimensions(product) {
    const dimensions = {};
  
    if (product?.front_image_base64) {
      dimensions.frontImage = await getImageDimensions(product.front_image_base64);
    }
  
    if (product?.back_image_base64) {
      dimensions.backImage = await getImageDimensions(product.back_image_base64);
    }
  
    if (product?.closeup_images_base64?.length) {
      dimensions.closeupImages = await Promise.all(
        product.closeup_images_base64.map(async (img, idx) => {
          const dimension = await getImageDimensions(img);
          return { index: idx + 1, dimension };
        })
      );
    }
  
    return dimensions;
  }
  
  export async function getAllProductImageDimensions(products) {
    return await Promise.all(
      products.map(async (product) => {
        const dimensions = await getProductImageDimensions(product);
        return {
          _id: product._id,
          dimensions,
        };
      })
    );
  }