export const setUserDetailsInLocalStorage = (userDetails) => {
  sessionStorage.setItem("userDetails", JSON.stringify(userDetails));
};
export const setUserDetailsInSessionStorage = (userDetails) => {
  sessionStorage.setItem("userDetails", JSON.stringify(userDetails));
};
export const getUserDetailsFromLocalStorage = () => {
  const storedUserDetails = sessionStorage.getItem("userDetails");
  return storedUserDetails ? JSON.parse(storedUserDetails) : null;
};

export const saveCurrentPageToLocalStorage = (page) => {
  localStorage.setItem("currentPage", page);
};

export const getCurrentPageFromLocalStorage = () => {
  return localStorage.getItem("currentPage");
};
