export async function addProductsToIndexedDB(dbName, storeName, version, products) {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(dbName, version);
  
      request.onupgradeneeded = event => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains(storeName)) {
          db.createObjectStore(storeName, { keyPath: '_id' });
        }
      };
  
      request.onsuccess = event => {
        const db = event.target.result;
  
        const transaction = db.transaction([storeName], 'readwrite');
        const store = transaction.objectStore(storeName);
  
        // Clear existing products before adding new ones
        const clearRequest = store.clear();
        clearRequest.onsuccess = () => {
          try {
            products.forEach(product => {
              if (!product.hasOwnProperty('_id')) {
                throw new Error('Each product must have an "_id" property.');
              }
              if (product.hasOwnProperty('back_image_base64:')) {
                product['back_image_base64'] = product['back_image_base64:'];
                delete product['back_image_base64:'];
              }
              store.put(product);
            });
  
            transaction.oncomplete = () => {
              resolve('All products have been added to the database.');
            };
  
            transaction.onerror = event => {
              reject(`Transaction failed: ${event.target.error}`);
            };
          } catch (error) {
            transaction.abort();
            reject(`Error adding products: ${error.message}`);
          }
        };
  
        clearRequest.onerror = event => {
          reject(`Failed to clear existing products: ${event.target.error}`);
        };
      };
  
      request.onerror = event => {
        reject(`Failed to open database: ${event.target.error}`);
      };
    });
  }
  
  export async function getProductsFromIndexedDB(dbName, storeName, version) {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(dbName, version);
  
      request.onsuccess = event => {
        const db = event.target.result;
  
        const transaction = db.transaction([storeName], 'readonly');
        const store = transaction.objectStore(storeName);
  
        const allProducts = store.getAll();
  
        allProducts.onsuccess = () => {
          resolve(allProducts.result);
        };
  
        allProducts.onerror = event => {
          reject(`Failed to retrieve data: ${event.target.error}`);
        };
      };
  
      request.onerror = event => {
        reject(`Failed to open database: ${event.target.error}`);
      };
    });
  }